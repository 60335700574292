import { WorkoutsFormFormSubContainer } from "../../../../../styles/workouts-common-styles";
import { ReadExerciseMediaController } from "./read-exercise-media-controller/read-exercise-media-controller";
import { ReadExerciseThumbnailController } from "./read-exercise-thumbnail-controller/read-exercise-thumbnail-controller";

export const ReadExerciseRightControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <ReadExerciseThumbnailController />
      <ReadExerciseMediaController />
    </WorkoutsFormFormSubContainer>
  );
};
