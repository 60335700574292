import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../../../styles/classes/reusable-classes";

type ReadLevelUiOrderProps = {
  uiOrder?: number;
};

export const ReadLevelUiOrder = (props: ReadLevelUiOrderProps) => {
  const { uiOrder } = props;

  return (
    <UXInputContainer>
      <UXInputLabel $width="150px">UI Order</UXInputLabel>
      <UXInputWrapper $width="80%" $readOnly>
        <UXInputInput
          readOnly
          width="100%"
          dir="auto"
          type="text"
          placeholder="UI Order"
          defaultValue={uiOrder}
        />
      </UXInputWrapper>
    </UXInputContainer>
  );
};
