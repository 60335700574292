import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../../../styles/classes/reusable-classes";

type ReadLevelDaysCountControllerProps = {
  daysCount?: number;
};

export const ReadLevelDaysCountController = (
  props: ReadLevelDaysCountControllerProps
) => {
  const { daysCount } = props;

  return (
    <UXInputContainer>
      <UXInputLabel $width="150px">Days Count</UXInputLabel>
      <UXInputWrapper $width="80%" $readOnly>
        <UXInputInput
          readOnly
          width="100%"
          dir="auto"
          type="text"
          placeholder="Enter Days Count"
          defaultValue={daysCount}
        />
      </UXInputWrapper>
    </UXInputContainer>
  );
};
