import {
  ChangeEvent,
  DragEvent,
  MutableRefObject,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

export type ImageVideoUploadState =
  | { state: "Idle" }
  | { state: "Upload"; previewUrl?: string }
  | { state: "Drag"; dragActive: boolean }
  | { state: "Uploaded"; previewUrl: string };

export type ImageVideoUploadHelpers = {
  handleRemoveMedia: () => void;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDrag: (event: DragEvent<HTMLFormElement | HTMLDivElement>) => void;
  handleDrop: (event: DragEvent<HTMLDivElement>) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  imageVideoFile: Blob | null;
  setImageVideoFile: (imageFile: Blob | null) => void;
  imageVideoUploadState: ImageVideoUploadState;
  setImageVideoUploadState: (
    imageVideoUploadState: ImageVideoUploadState
  ) => void;
  handleUploadedImageVideoState: (imageFile: Blob) => void;
  imageVideoFileInputRef: MutableRefObject<HTMLInputElement | null>;
};

const ImageVideoUploadHelpersContext = createContext<
  ImageVideoUploadHelpers | undefined
>(undefined);

type ImageVideoUploadHelpersProviderProps = PropsWithChildren<{}>;

export function ImageVideoUploadHelpersProvider(
  props: ImageVideoUploadHelpersProviderProps
) {
  const { children } = props;

  const [loading, setLoading] = useState(false);
  const [imageVideoFile, setImageVideoFile] = useState<Blob | null>(null);
  const [imageVideoUploadState, setImageVideoUploadState] = useState<
    ImageVideoUploadState
  >({
    state: "Idle",
  });

  const imageVideoFileInputRef = useRef<HTMLInputElement | null>(null);

  const handleRemoveMedia = useCallback(() => {
    if (
      imageVideoFile !== null &&
      (imageVideoUploadState.state === "Upload" ||
        imageVideoUploadState.state === "Uploaded")
    ) {
      setImageVideoFile(null);

      setImageVideoUploadState({
        state: "Upload",
        previewUrl: undefined,
      });
    }
  }, [
    imageVideoFile,
    imageVideoUploadState.state,
    setImageVideoFile,
    setImageVideoUploadState,
  ]);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (files && files.length > 0) {
        const selectedFiles = Array.from(files);
        const file = selectedFiles[0];

        if (file.type === "image/gif") {
          alert("Please upload a valid image file (no GIFs allowed).");
          return;
        }

        setImageVideoFile(file);

        const readerPromises = selectedFiles.map((file) => {
          return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result as string);
            };
            reader.readAsDataURL(file);
          });
        });

        Promise.all(readerPromises).then((previewUrls) => {
          setImageVideoUploadState({
            state: "Upload",
            previewUrl: previewUrls[0],
          });
        });
      }
    },
    []
  );

  const handleDrag = useCallback(
    (event: DragEvent<HTMLFormElement | HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (event.type === "dragenter" || event.type === "dragover") {
        setImageVideoUploadState({
          state: "Drag",
          dragActive: true,
        });
      } else if (event.type === "dragleave") {
        setImageVideoUploadState({
          state: "Drag",
          dragActive: false,
        });
      }
    },
    []
  );

  const handleDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;

    if (files && files.length > 0) {
      const selectedFiles = Array.from(files);
      const file = selectedFiles[0];

      if (!file.type.startsWith("image/") || file.type === "image/gif") {
        alert("Please upload a valid image file (no GIFs allowed).");
        setImageVideoUploadState({
          state: "Drag",
          dragActive: false,
        });
        return;
      }

      setImageVideoFile(selectedFiles[0]);

      const readerPromises = selectedFiles.map((file) => {
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(readerPromises).then((previewUrls) => {
        setImageVideoUploadState({
          state: "Upload",
          previewUrl: previewUrls[0],
        });
      });
    }
  }, []);

  const handleUploadedImageVideoState = useCallback((imageFile: Blob) => {
    const readerPromise = new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(imageFile);
    });

    Promise.all([readerPromise]).then((previewUrls) => {
      setImageVideoUploadState({
        state: "Uploaded",
        previewUrl: previewUrls[0],
      });
    });
  }, []);

  const value = useMemo(
    () => ({
      handleRemoveMedia,
      handleFileChange,
      handleDrag,
      handleDrop,
      loading,
      setLoading,
      imageVideoFile,
      setImageVideoFile,
      imageVideoUploadState,
      setImageVideoUploadState,
      handleUploadedImageVideoState,
      imageVideoFileInputRef,
    }),
    [
      handleRemoveMedia,
      handleFileChange,
      handleDrag,
      handleDrop,
      loading,
      setLoading,
      imageVideoFile,
      setImageVideoFile,
      imageVideoUploadState,
      setImageVideoUploadState,
      handleUploadedImageVideoState,
      imageVideoFileInputRef,
    ]
  );

  return (
    <ImageVideoUploadHelpersContext.Provider value={value}>
      {children}
    </ImageVideoUploadHelpersContext.Provider>
  );
}

export function useImageVideoUploadHelpers(): ImageVideoUploadHelpers {
  const context = useContext(ImageVideoUploadHelpersContext);

  if (context === undefined) {
    throw new Error(
      "useImageVideoUploadHelpers must be used within a ImageVideoUploadHelpersProvider"
    );
  }

  return context;
}
