import { Controller, useFormContext } from "react-hook-form";
import {
  RadioButton,
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../styles/classes/reusable-classes";
import { ReadProgramInputs } from "../../types/read-programs-inputs";
import {
  useReloadWorkoutProgram,
  useWorkoutProgram,
} from "../../../context/loadable-workout-program-context";
import { usePutWorkoutProgram } from "../../../../../../../hooks/workout-program-hooks/use-put-workout-program";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { CreateWorkoutProgramDTO } from "../../../../../../../models/workout-programs-dtos/create-workout-program-dtos/create-workout-program-dto";
import { CreateWorkoutProgramLevelDTO } from "../../../../../../../models/workout-programs-dtos/create-workout-program-dtos/create-workout-program-level-dto";

export const ReadProgramDraftController = () => {
  const { handleSubmit, control } = useFormContext<ReadProgramInputs>();
  const { workoutProgramDTO } = useWorkoutProgram();
  const reloadWorkoutProgram = useReloadWorkoutProgram();

  const putWorkoutProgram = usePutWorkoutProgram();
  const { showToast } = useToastService();

  const onSubmit = handleSubmit(async (inputs) => {
    try {
      const putWorkoutProgramDTO: CreateWorkoutProgramDTO = {
        name: workoutProgramDTO.name,
        isDefault: workoutProgramDTO.isDefault,
        isDraft: inputs.isDraft,
        coverPhotoUploadedFileId: workoutProgramDTO.coverPhotoUploadedFileId,
        workoutProgramLevels: workoutProgramDTO.workoutProgramLevels as CreateWorkoutProgramLevelDTO[],
      };

      await putWorkoutProgram(workoutProgramDTO.id, putWorkoutProgramDTO);
      reloadWorkoutProgram();
      showToast("Success", "Program Default Updated");
    } catch (error) {
      showToast("Error", "Faild to Update Program Default :(");
    }
  });

  return (
    <Controller
      control={control}
      name="isDraft"
      render={({ field: { value, onChange } }) => (
        <UXInputContainer $width="280px">
          <UXInputLabel $width="150px">Draft</UXInputLabel>
          <RadioButton
            type="button"
            checked={value}
            onClick={() => {
              onChange(!value);
              onSubmit();
            }}
          >
            <span />
          </RadioButton>
        </UXInputContainer>
      )}
    />
  );
};
