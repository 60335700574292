import { VideoUploadHelpersProvider } from "../../../../../../../content/components/video-upload/context/video-upload-helpers-context";
import { UpdateExerciseMediaContainer } from "./update-exercise-media-container";

export const UpdateExerciseMediaController = () => {
  return (
    <VideoUploadHelpersProvider>
      <UpdateExerciseMediaContainer />
    </VideoUploadHelpersProvider>
  );
};
