import { UseFieldArrayRemove, useFormContext } from "react-hook-form";
import { AddLevelInputs } from "../../../add-level/types/add-level-inputs";
import { useWorkouts } from "../../context/loadable-workouts-context";
import {
  WorkoutsContainerDayWorkoutContainer,
  WorkoutsContainerDayWorkoutDetail,
  WorkoutsContainerDayWorkoutDetails,
  WorkoutsContainerDayWorkoutHeader,
  WorkoutsContainerDayWorkoutHeaderActions,
  WorkoutsContainerDayWorkoutHeaderTitle,
  WorkoutsContainerDayWorkoutInformation,
} from "./styled-workouts-container-day-workout";
import { calculateWorkoutDetails } from "../utils/calculate-workout-details";
import { useCallback, useMemo } from "react";
import { WorkoutsContainerDayWorkoutFooter } from "./workouts-container-day-workout-exercises/styled-workouts-container-day-workout-exercises";
import { WorkoutsContainerDayWorkoutExercises } from "./workouts-container-day-workout-exercises/workouts-container-day-workout-exercises";
import { WorkoutsExercisesEditModal } from "../workouts-exercises-modal/workouts-exercises-edit-modal";

type WorkoutsContainerDayWorkoutProps = {
  dayNumber: number;
  remove: UseFieldArrayRemove;
  handleSort: () => void;
  dragItem: React.MutableRefObject<number>;
  draggedOverItem: React.MutableRefObject<number>;
  index: number;
};

export const WorkoutsContainerDayWorkout = (
  props: WorkoutsContainerDayWorkoutProps
) => {
  const {
    dayNumber,
    remove,
    handleSort,
    dragItem,
    draggedOverItem,
    index,
  } = props;

  const { exerciseDTOs } = useWorkouts();

  const { watch, setValue } = useFormContext<AddLevelInputs>();
  const workoutsWatcher = watch("workouts");

  const handleRemove = useCallback(() => {
    const indexOfTarget = workoutsWatcher?.findIndex(
      (item) => item.dayNumber === dayNumber
    );

    remove(indexOfTarget);
  }, [remove, dayNumber, workoutsWatcher]);

  const handleDuplicate = useCallback(() => {
    const targetItem = workoutsWatcher?.find(
      (item) => item.dayNumber === dayNumber
    );

    if (targetItem && workoutsWatcher) {
      setValue("workouts", [
        ...workoutsWatcher,
        {
          ...targetItem,
          dayNumber: workoutsWatcher.some(
            (item) => item.dayNumber === dayNumber
          )
            ? workoutsWatcher.length + 1
            : targetItem.dayNumber + 1,
        },
      ]);
    }
  }, [workoutsWatcher, dayNumber, setValue]);

  const { currentDayExercises, workoutInformation } = useMemo(() => {
    const workoutsExercises = workoutsWatcher
      ? workoutsWatcher
          .map((workout) =>
            workout.workoutExercises && workout.dayNumber === dayNumber
              ? workout.workoutExercises
              : []
          )
          .flat()
          .sort((a, b) => a.uiOrder - b.uiOrder)
      : [];

    const currentDayExercises = workoutsExercises.map(
      (item) => exerciseDTOs.find((ex) => ex.id === item.exerciseId)!
    );

    const currentWorkout = workoutsWatcher?.find(
      (workoutDTO) => workoutDTO.dayNumber === dayNumber
    );

    const workoutInformation = {
      name: currentWorkout?.name || "",
      description: currentWorkout?.description || "",
    };

    return { currentDayExercises, workoutInformation };
  }, [workoutsWatcher, exerciseDTOs, dayNumber]);

  return (
    <WorkoutsContainerDayWorkoutContainer
      draggable
      onDragStart={() => (dragItem.current = index)}
      onDragEnter={() => (draggedOverItem.current = index)}
      onDragEnd={handleSort}
      onDragOver={(e) => e.preventDefault()}
    >
      <WorkoutsContainerDayWorkoutHeader>
        <WorkoutsContainerDayWorkoutHeaderTitle>
          Day {dayNumber}
        </WorkoutsContainerDayWorkoutHeaderTitle>
        <WorkoutsContainerDayWorkoutHeaderActions>
          <span
            className="material-symbols-outlined"
            onClick={handleDuplicate}
            style={
              workoutsWatcher?.length! >= 7 ||
              (workoutsWatcher?.some((items) => items.dayNumber === 7) &&
                dayNumber === 7)
                ? {
                    opacity: "0.5",
                    pointerEvents: "none",
                  }
                : {}
            }
          >
            content_copy
          </span>
          <span className="material-symbols-outlined">menu</span>
          <span className="material-symbols-outlined" onClick={handleRemove}>
            delete
          </span>
        </WorkoutsContainerDayWorkoutHeaderActions>
      </WorkoutsContainerDayWorkoutHeader>
      <div>
        <WorkoutsContainerDayWorkoutInformation>
          <p>{workoutInformation.name}</p>
          <p>{workoutInformation.description}</p>
        </WorkoutsContainerDayWorkoutInformation>
        <WorkoutsContainerDayWorkoutDetails>
          <WorkoutsContainerDayWorkoutDetail>
            <span className="material-symbols-outlined">schedule</span>
            <p>
              {
                calculateWorkoutDetails(currentDayExercises)
                  .totalWorkoutDuration
              }{" "}
              mins
            </p>
          </WorkoutsContainerDayWorkoutDetail>
          <WorkoutsContainerDayWorkoutDetail>
            <span className="material-symbols-outlined">
              local_fire_department
            </span>
            <p>
              {calculateWorkoutDetails(currentDayExercises).totalBurnedKcals}{" "}
              kcal
            </p>
          </WorkoutsContainerDayWorkoutDetail>
        </WorkoutsContainerDayWorkoutDetails>
        <WorkoutsContainerDayWorkoutExercises
          currentDayExercises={currentDayExercises}
        />
      </div>
      <WorkoutsContainerDayWorkoutFooter>
        <WorkoutsExercisesEditModal
          day={dayNumber}
          setWorkoutsValue={setValue}
          workoutsWatcher={workoutsWatcher}
        />
      </WorkoutsContainerDayWorkoutFooter>
    </WorkoutsContainerDayWorkoutContainer>
  );
};
