import { createContext, PropsWithChildren, useContext } from "react";
import { UseLoadableDataReturn } from "../../../../hooks/use-loadable-data";
import { WorkoutProgramDTO } from "../../../../models/workout-programs-dtos/workout-program-dtos/workout-program-dto";

export type WorkoutPrograms = {
  workoutProgramDTOs: WorkoutProgramDTO[];
};

export type LoadableWorkoutPrograms = UseLoadableDataReturn<WorkoutPrograms>;

const LoadableWorkoutProgramsContext = createContext<
  LoadableWorkoutPrograms | undefined
>(undefined);

export type LoadableWorkoutProgramsProviderProps = PropsWithChildren<{
  loadableWorkoutPrograms: LoadableWorkoutPrograms;
}>;

export function LoadableWorkoutProgramsProvider(
  props: LoadableWorkoutProgramsProviderProps
) {
  const { loadableWorkoutPrograms, children } = props;

  return (
    <LoadableWorkoutProgramsContext.Provider value={loadableWorkoutPrograms}>
      {children}
    </LoadableWorkoutProgramsContext.Provider>
  );
}

export function useLoadableWorkoutPrograms(): LoadableWorkoutPrograms {
  const loadableWorkoutPrograms = useContext(LoadableWorkoutProgramsContext);

  if (loadableWorkoutPrograms === undefined) {
    throw Error(
      "useLoadableWorkoutPrograms must be used within an LoadableWorkoutProgramsProvider"
    );
  }

  return loadableWorkoutPrograms;
}

export function useWorkoutPrograms(): WorkoutPrograms {
  const loadableWorkoutPrograms = useLoadableWorkoutPrograms();

  if (loadableWorkoutPrograms.state.data === undefined) {
    throw Error("WorkoutPrograms have never been loaded successfully yet.");
  }

  return loadableWorkoutPrograms.state.data;
}

export function useReloadWorkoutPrograms(): () => void {
  const loadableWorkoutPrograms = useLoadableWorkoutPrograms();

  return loadableWorkoutPrograms.reload;
}
