import { createContext, PropsWithChildren, useContext, useState } from "react";

type State = "Read" | "Update";

type LevelState = {
  levelState: State;
  setLevelState: (LevelState: State) => void;
};

const LevelStateContext = createContext<LevelState | undefined>(undefined);

type LevelStateProviderProps = PropsWithChildren<{}>;

export function LevelStateProvider(props: LevelStateProviderProps) {
  const { children } = props;

  const [levelState, setLevelState] = useState<State>("Read");

  return (
    <LevelStateContext.Provider value={{ levelState, setLevelState }}>
      {children}
    </LevelStateContext.Provider>
  );
}

export const useLevelState = (): LevelState => {
  const context = useContext(LevelStateContext);

  if (!context) {
    throw new Error("useLevelState must be used within an LevelStateProvider");
  }

  return context;
};
