import { useCallback, useEffect } from "react";
import { useImageUploadHelpers } from "../../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { ImageUpload } from "../../../../../../../../components/image-upload/image-upload";
import { useFormContext } from "react-hook-form";
import { useToastService } from "../../../../../../../../context/toast-service-context";
import { useAddUploadedFile } from "../../../../../../../../hooks/uploaded-file-hooks/use-add-uploaded-file";
import { CreateUploadedFileDTO } from "../../../../../../../../models/uploaded-file-dtos/create-uploaded-file-dto";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../styles/classes/reusable-classes";
import { UpdateExerciseInputs } from "../../../types/update-exercise-inputs";
import { useExercise } from "../../../../context/loadable-exercise-context";

export const UpdateExerciseThumbnailContainer = () => {
  const { exerciseDTO } = useExercise();

  const {
    imageFile,
    imageUploadState,
    setImageFile,
    setImageUploadState,
    handleUploadedImageState,
    setLoading,
  } = useImageUploadHelpers();

  const addUploadedFile = useAddUploadedFile();
  const { showToast } = useToastService();
  const { setValue } = useFormContext<UpdateExerciseInputs>();

  useEffect(() => {
    if (exerciseDTO.thumbnailUploadedFile) {
      setImageUploadState({
        state: "Uploaded",
        previewUrl: exerciseDTO.thumbnailUploadedFile.presignedUrl,
      });

      const jsonThumbnailUploadedFile = JSON.stringify(
        exerciseDTO.thumbnailUploadedFile
      );
      const blob = new Blob([jsonThumbnailUploadedFile], {
        type: "application/json",
      });
      setImageFile(blob);
    }
  }, [setImageUploadState, exerciseDTO.thumbnailUploadedFile, setImageFile]);

  const onUpload = useCallback(async () => {
    try {
      if (imageFile) {
        const createUploadedFileDTO: CreateUploadedFileDTO = {
          feature: "ContentModuleThumbnail",
        };

        setLoading(true);
        await addUploadedFile(
          createUploadedFileDTO,
          imageFile
        ).then((response) => setValue("thumbnailUploadedFileId", response.id));
        handleUploadedImageState(imageFile);
        showToast("Success", "Thumbnail Uploaded Successfully");
        setLoading(false);
      }
    } catch (error) {
      showToast("Error", "Failed to Upload Thumbnail :(");
    }
  }, [
    imageFile,
    setLoading,
    showToast,
    addUploadedFile,
    handleUploadedImageState,
    setValue,
  ]);

  useEffect(() => {
    if (imageFile && imageUploadState.state === "Upload") {
      onUpload();
    } else if (
      imageUploadState.state === "Upload" &&
      imageUploadState.previewUrl === undefined
    ) {
      setValue("thumbnailUploadedFileId", undefined);
    }
  }, [imageFile, onUpload, imageUploadState, setValue]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px" $isOptional>
        Exercise Thumbnail
        <span>(Optional)</span>
      </UXInputLabel>
      <ImageUpload />
    </UXInputContainer>
  );
};
