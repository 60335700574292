import { useCallback } from "react";
import { useGetOneWorkoutProgram } from "../../../../hooks/workout-program-hooks/use-get-one-workout-program";
import { useParams } from "react-router-dom";
import { useLoadableData } from "../../../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../../../components/loadable-data-loading";
import { ProgramState } from "./program-state";
import { LoadableWorkoutProgramProvider } from "./context/loadable-workout-program-context";
import { ProgramStateProvider } from "./context/program-state-context";

export const Program = () => {
  const { programID } = useParams();

  const getOneWorkoutProgram = useGetOneWorkoutProgram();

  const getOneWorkoutProgramCallback = useCallback(async () => {
    const id = parseInt(programID!);
    const [workoutProgramDTO] = await Promise.all([getOneWorkoutProgram(id)]);

    return { workoutProgramDTO };
  }, [getOneWorkoutProgram, programID]);

  const loadableWorkoutProgram = useLoadableData(getOneWorkoutProgramCallback);

  return (
    <LoadableDataLoading
      state={loadableWorkoutProgram.state}
      successComponent={() => (
        <LoadableWorkoutProgramProvider
          loadableWorkoutProgram={loadableWorkoutProgram}
        >
          <ProgramStateProvider>
            <ProgramState />
          </ProgramStateProvider>
        </LoadableWorkoutProgramProvider>
      )}
    />
  );
};
