import { createContext, PropsWithChildren, useContext } from "react";
import { ExerciseDTO } from "../../../../../models/exercises-dtos/exercise-dto";
import { UseLoadableDataReturn } from "../../../../../hooks/use-loadable-data";

export type Exercise = {
  exerciseDTO: ExerciseDTO;
};

export type LoadableExercise = UseLoadableDataReturn<Exercise>;

const LoadableExerciseContext = createContext<LoadableExercise | undefined>(
  undefined
);

export type LoadableExerciseProviderProps = PropsWithChildren<{
  loadableExercise: LoadableExercise;
}>;

export function LoadableExerciseProvider(props: LoadableExerciseProviderProps) {
  const { loadableExercise, children } = props;

  return (
    <LoadableExerciseContext.Provider value={loadableExercise}>
      {children}
    </LoadableExerciseContext.Provider>
  );
}

export function useLoadableExercise(): LoadableExercise {
  const loadableExercise = useContext(LoadableExerciseContext);

  if (loadableExercise === undefined) {
    throw Error(
      "useLoadableExercise must be used within an LoadableExerciseProvider"
    );
  }

  return loadableExercise;
}

export function useExercise(): Exercise {
  const loadableExercise = useLoadableExercise();

  if (loadableExercise.state.data === undefined) {
    throw Error("Exercise have never been loaded successfully yet.");
  }

  return loadableExercise.state.data;
}

export function useReloadExercise(): () => void {
  const loadableExercise = useLoadableExercise();

  return loadableExercise.reload;
}
