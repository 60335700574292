import { useFormContext } from "react-hook-form";
import { useToastService } from "../../../../../../../../../context/toast-service-context";
import { useAddUploadedFile } from "../../../../../../../../../hooks/uploaded-file-hooks/use-add-uploaded-file";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../../styles/classes/reusable-classes";
import { ContentModuleUpdateModeInputs } from "../../../../../../../context/content-module-mode-context";
import { useCallback, useEffect } from "react";
import { CreateUploadedFileDTO } from "../../../../../../../../../models/uploaded-file-dtos/create-uploaded-file-dto";
import { UploadedFileWithPresignedUrlDTO } from "../../../../../../../../../models/uploaded-file-dtos/uploaded-file-with-presigned-url-dto";
import { ContentModuleCardPartType } from "../../../../../../../../../models/content-module-dtos/content-module-card-part-type";
import { useCardPartsLoading } from "../../../../../../context/card-parts-loading-context";
import { ImageUpload } from "../../../../../../../../../components/image-upload/image-upload";
import { useImageUploadHelpers } from "../../../../../../../../../components/image-upload/context/image-upload-helpers-context";

type LessonUpdateModeCardImagePartControllerProps = {
  cardIndex: number;
  cardPartIndex: number;
};

export const LessonUpdateModeCardImagePartController = (
  props: LessonUpdateModeCardImagePartControllerProps
) => {
  const { cardIndex, cardPartIndex } = props;

  const {
    imageFile,
    imageUploadState,
    setImageFile,
    setImageUploadState,
    handleUploadedImageState,
    setLoading,
    imageFileInputRef,
  } = useImageUploadHelpers();

  const { setCardPartsLoading } = useCardPartsLoading();

  const addUploadedFile = useAddUploadedFile();
  const { showToast } = useToastService();
  const { setValue, watch } = useFormContext<ContentModuleUpdateModeInputs>();

  type ContentModuleCardPartWatcher = {
    contentModuleCardPartType: ContentModuleCardPartType;
    uiOrder: number;
    imageUploadedFileId?: number;
    imageUploadedFile?: UploadedFileWithPresignedUrlDTO;
  };

  const contentModuleCardPartWatcher: ContentModuleCardPartWatcher = watch(
    `contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}`
  );

  useEffect(() => {
    if (contentModuleCardPartWatcher.imageUploadedFile) {
      setImageUploadState({
        state: "Uploaded",
        previewUrl: contentModuleCardPartWatcher.imageUploadedFile.presignedUrl,
      });

      const jsonThumbnailUploadedFile = JSON.stringify(
        contentModuleCardPartWatcher.imageUploadedFile
      );
      const blob = new Blob([jsonThumbnailUploadedFile], {
        type: "application/json",
      });
      setImageFile(blob);
    }
  }, [
    setImageUploadState,
    contentModuleCardPartWatcher.imageUploadedFile,
    setImageFile,
  ]);

  const onUpload = useCallback(async () => {
    try {
      if (imageFile) {
        const createUploadedFileDTO: CreateUploadedFileDTO = {
          feature: "ContentModuleCardPartImage",
        };

        setLoading(true);
        setCardPartsLoading({
          cardIndex,
          loading: true,
        });
        await addUploadedFile(createUploadedFileDTO, imageFile).then(
          (response) => {
            setValue(
              `contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}.imageUploadedFile`,
              {
                id: response.id,
                presignedUrl: response.presignedUrl,
              }
            );
            setValue(
              `contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}.imageUploadedFileId`,
              response.id
            );
          }
        );
        handleUploadedImageState(imageFile);
        showToast("Success", "Card Image Uploaded Successfully");
        setLoading(false);
        setCardPartsLoading({
          cardIndex: undefined,
          loading: false,
        });
      }
    } catch (error) {
      showToast("Error", "Failed to Upload Card Image :(");
    }
  }, [
    imageFile,
    setLoading,
    showToast,
    addUploadedFile,
    handleUploadedImageState,
    setValue,
    cardIndex,
    cardPartIndex,
    setCardPartsLoading,
  ]);

  useEffect(() => {
    if (imageFile && imageUploadState.state === "Upload") {
      onUpload();
    } else if (
      imageUploadState.state === "Upload" &&
      imageUploadState.previewUrl === undefined
    ) {
      setValue(
        `contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}.imageUploadedFileId`,
        undefined
      );
    }
  }, [
    imageFile,
    onUpload,
    imageUploadState,
    setValue,
    cardIndex,
    cardPartIndex,
    imageUploadState.state,
  ]);

  const openUploaderOnMount = useCallback(() => {
    imageFileInputRef.current?.click();
  }, [imageFileInputRef]);

  useEffect(() => {
    if (imageUploadState.state === "Idle") {
      setTimeout(() => {
        openUploaderOnMount();
      }, 300);
    }
  }, [openUploaderOnMount, imageUploadState.state]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="50px" $isOptional>
        Image
      </UXInputLabel>
      <ImageUpload uploadText="Image" />
    </UXInputContainer>
  );
};
