import { WorkoutsFormFormSubContainer } from "../../../../../styles/workouts-common-styles";
import { ReadExerciseBurnedKcalsController } from "./read-exercise-burned-kcals-controller";
import { ReadExerciseDescriptionController } from "./read-exercise-description-controller";
import { ReadExerciseDurationMinsController } from "./read-exercise-duration-in-minutes";
import { ReadExerciseNameController } from "./read-exercise-name-controller";

export const ReadExerciseLeftControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <ReadExerciseNameController />
      <ReadExerciseBurnedKcalsController />
      <ReadExerciseDurationMinsController />
      <ReadExerciseDescriptionController />
    </WorkoutsFormFormSubContainer>
  );
};
