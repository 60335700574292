import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
  WorkoutsFormFormSubContainer,
  WorkoutsScreenHeader,
} from "../../styles/workouts-common-styles";
import { Button } from "../../../../styles/classes/reusable-classes";
import { FormProvider, useForm } from "react-hook-form";
import { AddProgramInputs } from "./types/add-program-inputs";
import { useToastService } from "../../../../context/toast-service-context";
import { AddProgramLeftControllers } from "./controllers/add-program-left-controllers/add-program-left-controllers";
import { ProgramCoverPhotoController } from "./controllers/add-program-right-controllers/add-program-cover-photo-controller";
import { useAddWorkoutProgram } from "../../../../hooks/workout-program-hooks/use-add-workout-program";
import { CreateWorkoutProgramDTO } from "../../../../models/workout-programs-dtos/create-workout-program-dtos/create-workout-program-dto";
import { useNavigate } from "react-router-dom";

export const AddProgram = () => {
  const addProgramFormMethods = useForm<AddProgramInputs>({
    defaultValues: { isDraft: true },
  });
  const { showToast } = useToastService();

  const navigate = useNavigate();
  const addWorkoutProgram = useAddWorkoutProgram();

  const onSubmit = addProgramFormMethods.handleSubmit(async (inputs) => {
    try {
      const createWorkoutProgramDTO: CreateWorkoutProgramDTO = {
        name: inputs.name,
        isDefault: inputs.isDefault,
        isDraft: inputs.isDraft,
        coverPhotoUploadedFileId: inputs.coverPhotoUploadedFileId,
        workoutProgramLevels: undefined,
      };

      await addWorkoutProgram(createWorkoutProgramDTO).then((res) =>
        navigate(`/workouts/program/${res.id}`)
      );
      showToast("Success", "Program Added Successfuly");
    } catch (error) {
      showToast("Error", "Failed to Add Program :(");
    }
  });

  return (
    <FormProvider {...addProgramFormMethods}>
      <form onSubmit={onSubmit}>
        <WorkoutsScreenHeader>
          <p>Program</p>
          <Button type="submit">Save</Button>
        </WorkoutsScreenHeader>
        <WorkoutsFormContainer>
          <WorkoutsFormControllersContainer>
            <AddProgramLeftControllers />
            <WorkoutsFormFormSubContainer>
              <ProgramCoverPhotoController />
            </WorkoutsFormFormSubContainer>
          </WorkoutsFormControllersContainer>
        </WorkoutsFormContainer>
      </form>
    </FormProvider>
  );
};
