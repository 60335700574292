import { ImageVideoUploadHelpersProvider } from "../../../../../../../components/image-video-upload/context/image-video-upload-helpers-context";
import { ExerciseMediaContainer } from "./exercise-media-container";

export const ExerciseMediaController = () => {
  return (
    <ImageVideoUploadHelpersProvider>
      <ExerciseMediaContainer />
    </ImageVideoUploadHelpersProvider>
  );
};
