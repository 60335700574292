import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { ImageUploadHelpersProvider } from "../../../../../components/image-upload/context/image-upload-helpers-context";
import { ContentModuleDTO } from "../../../../../models/content-module-dtos/content-module-dto";
import { ContentModuleModeProvider } from "../../../context/content-module-mode-context";
import { ContentScreenContainer } from "../../../styles/content-reusable-classes";
import { CardPartsLoadingContextProvider } from "../../context/card-parts-loading-context";
import { ContentCourseModuleLessonModes } from "./content-course-module-lesson-modes";

type ContentCourseModuleLessonBodyProps = {
  contentCourseModuleLesson?: ContentModuleDTO;
};

export const ContentCourseModuleLessonBody = (
  props: ContentCourseModuleLessonBodyProps
) => {
  const { contentCourseModuleLesson } = props;

  if (contentCourseModuleLesson === undefined) {
    return (
      <EmptyState
        button={<></>}
        hasHeader={false}
        hasSearch={false}
        text="Something went wrong please reload the page or try again"
        title=""
      />
    );
  }

  return (
    <ContentModuleModeProvider contentModuleDTO={contentCourseModuleLesson}>
      <ContentScreenContainer $lessonUI>
        <ImageUploadHelpersProvider>
          <CardPartsLoadingContextProvider>
            <ContentCourseModuleLessonModes />
          </CardPartsLoadingContextProvider>
        </ImageUploadHelpersProvider>
      </ContentScreenContainer>
    </ContentModuleModeProvider>
  );
};
