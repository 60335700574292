import { ImageUploadHelpersProvider } from "../../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { ReadExerciseThumbnailContainer } from "./read-exercise-thumbnail-container";

export const ReadExerciseThumbnailController = () => {
  return (
    <ImageUploadHelpersProvider>
      <ReadExerciseThumbnailContainer />
    </ImageUploadHelpersProvider>
  );
};
