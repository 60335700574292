import { TH } from "../../../../../../components/table/styled-table";

export const LavelsTableHeadings = () => {
  const tableHeadings = [
    "Cover Photo",
    "Name",
    "Days Count",
    "UI Order",
    "Actions",
  ].map((heading, index) => (
    <TH key={index} actions={heading === "Actions" && true}>
      <div>{heading}</div>
    </TH>
  ));

  return <>{tableHeadings}</>;
};
