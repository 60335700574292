import { ImageUploadHelpersProvider } from "../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { UpdateProgramCoverPhotoContainer } from "./update-program-cover-photo-container";

export const UpdateProgramCoverPhotoController = () => {
  return (
    <ImageUploadHelpersProvider>
      <UpdateProgramCoverPhotoContainer />
    </ImageUploadHelpersProvider>
  );
};
