import { UseFieldArrayRemove, useFormContext } from "react-hook-form";
import {
  WorkoutsContainerDayWorkoutContainer,
  WorkoutsContainerDayWorkoutHeader,
  WorkoutsContainerDayWorkoutHeaderActions,
  WorkoutsContainerDayWorkoutHeaderTitle,
} from "./styled-workouts-container-day-workout";
import { AddLevelInputs } from "../../../add-level/types/add-level-inputs";
import { useCallback } from "react";

type WorkoutsContainerRestDayCardProps = {
  dayNumber: number;
  remove: UseFieldArrayRemove;
  handleSort: () => void;
  dragItem: React.MutableRefObject<number>;
  draggedOverItem: React.MutableRefObject<number>;
  index: number;
};

export const WorkoutsContainerRestDayCard = (
  props: WorkoutsContainerRestDayCardProps
) => {
  const {
    dayNumber,
    remove,
    handleSort,
    dragItem,
    draggedOverItem,
    index,
  } = props;

  const { watch } = useFormContext<AddLevelInputs>();
  const workoutsWatcher = watch("workouts");

  const handleRemove = useCallback(() => {
    const indexOfTarget = workoutsWatcher?.findIndex(
      (item) => item.dayNumber === dayNumber
    );

    remove(indexOfTarget);
  }, [remove, dayNumber, workoutsWatcher]);

  return (
    <WorkoutsContainerDayWorkoutContainer
      style={{ width: "150px", height: "430px" }}
      draggable
      onDragStart={() => (dragItem.current = index)}
      onDragEnter={() => (draggedOverItem.current = index)}
      onDragEnd={handleSort}
      onDragOver={(e) => e.preventDefault()}
    >
      <WorkoutsContainerDayWorkoutHeader
        style={{ justifyContent: "space-between" }}
      >
        <WorkoutsContainerDayWorkoutHeaderTitle
          style={{ margin: "0px", transform: "translateX(0px)" }}
        >
          Day {dayNumber}
        </WorkoutsContainerDayWorkoutHeaderTitle>
        <WorkoutsContainerDayWorkoutHeaderActions style={{ margin: "0px" }}>
          <span className="material-symbols-outlined">menu</span>
          <span className="material-symbols-outlined" onClick={handleRemove}>
            delete
          </span>
        </WorkoutsContainerDayWorkoutHeaderActions>
      </WorkoutsContainerDayWorkoutHeader>
      <p className="rest-day-text">Rest</p>
    </WorkoutsContainerDayWorkoutContainer>
  );
};
