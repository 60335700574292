import { useCallback } from "react";
import { LoadableDataLoading } from "../../../components/loadable-data-loading";
import { useGetAllExercises } from "../../../hooks/exercises/use-get-all-exercises";
import { useLoadableData } from "../../../hooks/use-loadable-data";
import { LoadableExercisesProvider } from "./context/loadable-exercises-context";
import { ExercisesTable } from "./exercises-table/exercises-table";

export const Exercises = () => {
  const getAllExercises = useGetAllExercises();

  const exercisesLoadableCallback = useCallback(async () => {
    const [exercises] = await Promise.all([getAllExercises()]);

    return { exercises };
  }, [getAllExercises]);

  const loadableExersices = useLoadableData(exercisesLoadableCallback);

  return (
    <LoadableDataLoading
      state={loadableExersices.state}
      successComponent={() => (
        <LoadableExercisesProvider loadableExercises={loadableExersices}>
          <ExercisesTable />
        </LoadableExercisesProvider>
      )}
    />
  );
};
