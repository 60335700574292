import { useCallback, useEffect } from "react";
import { useImageUploadHelpers } from "../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { ImageUpload } from "../../../../../../../components/image-upload/image-upload";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../styles/classes/reusable-classes";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { useAddUploadedFile } from "../../../../../../../hooks/uploaded-file-hooks/use-add-uploaded-file";
import { useFormContext } from "react-hook-form";
import { UpdateProgramInputs } from "../../types/update-program-form-inputs";
import { CreateUploadedFileDTO } from "../../../../../../../models/uploaded-file-dtos/create-uploaded-file-dto";
import { useWorkoutProgram } from "../../../context/loadable-workout-program-context";

export const UpdateProgramCoverPhotoContainer = () => {
  const { workoutProgramDTO } = useWorkoutProgram();

  const {
    imageFile,
    imageUploadState,
    setImageFile,
    setImageUploadState,
    handleUploadedImageState,
    setLoading,
  } = useImageUploadHelpers();

  const addUploadedFile = useAddUploadedFile();
  const { showToast } = useToastService();
  const { setValue } = useFormContext<UpdateProgramInputs>();

  useEffect(() => {
    if (workoutProgramDTO.coverPhotoUploadedFile) {
      setImageUploadState({
        state: "Uploaded",
        previewUrl: workoutProgramDTO.coverPhotoUploadedFile.presignedUrl,
      });

      const jsonThumbnailUploadedFile = JSON.stringify(
        workoutProgramDTO.coverPhotoUploadedFile
      );
      const blob = new Blob([jsonThumbnailUploadedFile], {
        type: "application/json",
      });
      setImageFile(blob);
    }
  }, [
    setImageUploadState,
    workoutProgramDTO.coverPhotoUploadedFile,
    setImageFile,
  ]);

  const onUpload = useCallback(async () => {
    try {
      if (imageFile) {
        const createUploadedFileDTO: CreateUploadedFileDTO = {
          feature: "ContentModuleThumbnail",
        };

        setLoading(true);
        await addUploadedFile(
          createUploadedFileDTO,
          imageFile
        ).then((response) => setValue("coverPhotoUploadedFileId", response.id));
        handleUploadedImageState(imageFile);
        showToast("Success", "Thumbnail Uploaded Successfully");
        setLoading(false);
      }
    } catch (error) {
      showToast("Error", "Failed to Upload Thumbnail :(");
    }
  }, [
    imageFile,
    setLoading,
    showToast,
    addUploadedFile,
    handleUploadedImageState,
    setValue,
  ]);

  useEffect(() => {
    if (imageFile && imageUploadState.state === "Upload") {
      onUpload();
    } else if (
      imageUploadState.state === "Upload" &&
      imageUploadState.previewUrl === undefined
    ) {
      setValue("coverPhotoUploadedFileId", undefined);
    }
  }, [imageFile, onUpload, imageUploadState, setValue]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px" $isOptional>
        Program CoverPhoto
      </UXInputLabel>
      <ImageUpload uploadText="Cover Photo" />
    </UXInputContainer>
  );
};
