import { useMemo } from "react";
import { useWorkoutExercisesModalAutoSelectSearchTerm } from "../context/workout-exercises-modal-auto-select-search-term-context";
import { useWorkouts } from "../../../../../context/loadable-workouts-context";
import { ExerciseDTO } from "../../../../../../../../../../../models/exercises-dtos/exercise-dto";
import { WorkoutsExercisesAutoSelectListList } from "./styled-workouts-exercises-modal-auto-select-list";
import { useFormContext } from "react-hook-form";
import { WorkoutExercisesInputs } from "../../../types/workout-exercises-inputs";
import {
  ExerciseListItemImageContainer,
  ExerciseListItemInfoContainer,
  ExerciseListItemInfoItem,
  ExerciseListItemInfoSubContainer,
  ExerciseListItemListItem,
  ExerciseListItemTextContainer,
} from "../../../../../styles/styled-exercise-list-item";

type WorkoutExercisesAutoSelectListProps = {
  showDropdown: boolean;
  append: (exerciseDTO: ExerciseDTO, index: number) => void;
};

export const WorkoutExercisesAutoSelectList = (
  props: WorkoutExercisesAutoSelectListProps
) => {
  const { showDropdown, append } = props;

  const { searchTerm } = useWorkoutExercisesModalAutoSelectSearchTerm();
  const { exerciseDTOs } = useWorkouts();

  const { watch } = useFormContext<WorkoutExercisesInputs>();
  const workoutExercises = watch("workoutExercises");

  const filterExerciseDTOs = useMemo(() => {
    const addedExercises: ExerciseDTO[] = [];

    if (workoutExercises) {
      for (const exerciseDTO of exerciseDTOs) {
        if (
          !workoutExercises.some((item) => item.exerciseId === exerciseDTO.id)
        ) {
          addedExercises.push(exerciseDTO);
        }
      }
      return addedExercises;
    } else {
      return exerciseDTOs;
    }
  }, [workoutExercises, exerciseDTOs]);

  const searchedWorkoutExercises = useMemo(
    () =>
      filterExerciseDTOs.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [filterExerciseDTOs, searchTerm]
  );

  const mappedExerciseDTOs = useMemo(
    () =>
      searchedWorkoutExercises.map((exerciseDTO, index) => (
        <ExerciseListItemListItem
          key={exerciseDTO.id}
          onClick={() => append(exerciseDTO, index)}
          $hoverActive
        >
          <ExerciseListItemImageContainer $width="50px" $height="60px">
            {exerciseDTO.thumbnailUploadedFile ? (
              <img
                src={exerciseDTO.thumbnailUploadedFile?.presignedUrl}
                alt={exerciseDTO.name}
              />
            ) : (
              <span className="material-symbols-outlined">directions_run</span>
            )}
          </ExerciseListItemImageContainer>
          <ExerciseListItemTextContainer>
            <p>{exerciseDTO.name}</p>
            <span>{exerciseDTO.description}</span>
          </ExerciseListItemTextContainer>
          <ExerciseListItemInfoContainer>
            <ExerciseListItemInfoSubContainer>
              <ExerciseListItemInfoItem>
                <span className="material-symbols-outlined">schedule</span>
                <p>{exerciseDTO.durationInMins} mins</p>
              </ExerciseListItemInfoItem>
              <ExerciseListItemInfoItem>
                <span className="material-symbols-outlined">
                  local_fire_department
                </span>
                <p>{exerciseDTO.burnedKCals} kcal</p>
              </ExerciseListItemInfoItem>
            </ExerciseListItemInfoSubContainer>
            <span className="material-symbols-outlined exercise-list-item-icon">
              add
            </span>
          </ExerciseListItemInfoContainer>
        </ExerciseListItemListItem>
      )),
    [searchedWorkoutExercises, append]
  );

  if (searchedWorkoutExercises.length === 0 && searchTerm !== "") {
    return (
      <WorkoutsExercisesAutoSelectListList $showDropdown={showDropdown}>
        <ExerciseListItemListItem $disabled>
          <span>No Results...</span>
        </ExerciseListItemListItem>
      </WorkoutsExercisesAutoSelectListList>
    );
  }

  return (
    <WorkoutsExercisesAutoSelectListList $showDropdown={showDropdown}>
      {mappedExerciseDTOs}
    </WorkoutsExercisesAutoSelectListList>
  );
};
