import {
  TD,
  TDContainer,
  TDLink,
} from "../../../../../../components/table/styled-table";
import { WorkoutsTableRowsThumbnailContainer } from "../../../../styles/workouts-common-styles";
import { WorkoutProgramLevelDTO } from "../../../../../../models/workout-programs-dtos/workout-program-dtos/workout-program-level-dto";
import { GenericActionsListItemItem } from "../../../../../../components/generic-actions-list/styled-generic-actions-list";
import { Link, useParams } from "react-router-dom";
import { GenericActionsList } from "../../../../../../components/generic-actions-list/generic-actions-list";
import { DeleteLevelModal } from "./delete-level-modal";

type LevelsTableRowsProps = {
  levelDTOs: WorkoutProgramLevelDTO[] | undefined;
};

export const LevelsTableRows = (props: LevelsTableRowsProps) => {
  const { levelDTOs } = props;

  const { programID } = useParams();

  const tableRows = levelDTOs
    ? levelDTOs.map((levelDTO) => (
        <tr key={levelDTO.id}>
          <TD width={100}>
            <WorkoutsTableRowsThumbnailContainer>
              {levelDTO.coverPhotoUploadedFile?.presignedUrl && (
                <img
                  src={levelDTO.coverPhotoUploadedFile?.presignedUrl}
                  alt={`${levelDTO.name}'s Level Thumbnail`}
                />
              )}
            </WorkoutsTableRowsThumbnailContainer>
          </TD>
          <TD>
            <TDContainer>
              <TDLink>
                <Link
                  to={`/workouts/program/${programID}/level/${levelDTO.id}`}
                >
                  {levelDTO.name}
                </Link>
              </TDLink>
            </TDContainer>
          </TD>
          <TD width={100}>{levelDTO.daysCount}</TD>
          <TD width={100}>{levelDTO.uiOrder}</TD>
          <TD width={100}>
            <GenericActionsList>
              <GenericActionsListItemItem>
                <Link
                  to={`/workouts/program/${programID}/level/${levelDTO.id}`}
                >
                  View
                </Link>
              </GenericActionsListItemItem>
              <DeleteLevelModal levelID={levelDTO.id} />
            </GenericActionsList>
          </TD>
        </tr>
      ))
    : [];

  return <>{tableRows}</>;
};
