import { Controller, useFormContext } from "react-hook-form";
import {
  RadioButton,
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../styles/classes/reusable-classes";
import { UpdateProgramInputs } from "../../types/update-program-form-inputs";

export const UpdateProgramDraftController = () => {
  const { control } = useFormContext<UpdateProgramInputs>();

  return (
    <Controller
      control={control}
      name="isDraft"
      render={({ field: { value, onChange } }) => (
        <UXInputContainer $width="280px">
          <UXInputLabel $width="150px">Draft</UXInputLabel>
          <RadioButton
            type="button"
            checked={value}
            onClick={() => onChange(!value)}
          >
            <span />
          </RadioButton>
        </UXInputContainer>
      )}
    />
  );
};
