import { FormProvider, useForm } from "react-hook-form";
import { Button } from "../../../../../../../styles/classes/reusable-classes";
import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
  WorkoutsFormFormSubContainer,
  WorkoutsScreenHeader,
} from "../../../../../styles/workouts-common-styles";
import { useLevelState } from "../context/level-state-context";
import { UpdateLevelInputs } from "./types/update-level-inputs";
import {
  useReloadWorkoutProgram,
  useWorkoutProgram,
} from "../../../context/loadable-workout-program-context";
import { useNavigate, useParams } from "react-router-dom";
import { UpdateLevelLeftControllers } from "./controllers/update-level-left-controllers/update-level-left-controllers";
import { UpdateLevelCoverPhotoController } from "./controllers/update-level-right-controllers/update-level-cover-photo-controller";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { usePutWorkoutProgram } from "../../../../../../../hooks/workout-program-hooks/use-put-workout-program";
import { PutWorkoutProgramDTO } from "../../../../../../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-program-dto";
import { PutWorkoutProgramLevelDTO } from "../../../../../../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-program-level-dto";

export const UpdateLevelForm = () => {
  const { setLevelState } = useLevelState();
  const { workoutProgramDTO } = useWorkoutProgram();
  const reloadWorkoutProgram = useReloadWorkoutProgram();
  const { showToast } = useToastService();
  const { levelID } = useParams();
  const putWorkoutProgram = usePutWorkoutProgram();
  const navigate = useNavigate();

  const currentLevel = workoutProgramDTO.workoutProgramLevels?.find(
    (item) => item.id === parseInt(levelID!)
  );

  const updateLevelFormMethods = useForm<UpdateLevelInputs>({
    defaultValues: {
      coverPhotoUploadedFileId: currentLevel?.coverPhotoUploadedFileId,
      daysCount: currentLevel?.daysCount,
      name: currentLevel?.name,
      uiOrder: currentLevel?.uiOrder,
      workouts: currentLevel?.workouts,
    },
  });

  const onSubmit = updateLevelFormMethods.handleSubmit(async (inputs) => {
    try {
      if (currentLevel) {
        const newLevel: PutWorkoutProgramLevelDTO = {
          daysCount: Number(inputs.daysCount),
          name: inputs.name,
          uiOrder: Number(inputs.uiOrder),
          coverPhotoUploadedFileId: inputs.coverPhotoUploadedFileId,
          workouts: undefined,
        };

        const newWorkoutLevels = workoutProgramDTO.workoutProgramLevels?.map(
          (item) => {
            if (item.id === currentLevel.id) {
              return {
                ...newLevel,
                id: currentLevel.id,
              };
            } else {
              return item;
            }
          }
        );

        const putWorkoutProgramDTO: PutWorkoutProgramDTO = {
          ...workoutProgramDTO,
          workoutProgramLevels: newWorkoutLevels as PutWorkoutProgramLevelDTO[],
        };

        await putWorkoutProgram(
          workoutProgramDTO.id,
          putWorkoutProgramDTO
        ).then((res) =>
          navigate(`/workouts/program/${res.id}/level/${levelID}`)
        );
        showToast("Success", "Level Updated Successfully");
        setLevelState("Read");
        reloadWorkoutProgram();
      }
    } catch (error) {
      showToast("Error", "Failed to update Level :(");
    }
  });

  return (
    <FormProvider {...updateLevelFormMethods}>
      <form onSubmit={onSubmit}>
        <WorkoutsScreenHeader>
          <p>Level</p>
          <Button type="submit">Save</Button>
        </WorkoutsScreenHeader>
        <WorkoutsFormContainer>
          <WorkoutsFormControllersContainer>
            <UpdateLevelLeftControllers />
            <WorkoutsFormFormSubContainer>
              <UpdateLevelCoverPhotoController />
            </WorkoutsFormFormSubContainer>
          </WorkoutsFormControllersContainer>
        </WorkoutsFormContainer>
      </form>
    </FormProvider>
  );
};
