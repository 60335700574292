import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UpdateExerciseInputs } from "./types/update-exercise-inputs";
import { useToastService } from "../../../../../context/toast-service-context";
import { PutExerciseDTO } from "../../../../../models/exercises-dtos/put-exercise-dto";
import { usePutExercise } from "../../../../../hooks/exercises/use-put-exercise";
import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
  WorkoutsScreenHeader,
} from "../../../styles/workouts-common-styles";
import { Button } from "../../../../../styles/classes/reusable-classes";
import { useExerciseState } from "../context/exercise-state-context";
import { UpdateExerciseLeftControllers } from "./controllers/update-exercise-left-controllers/update-exercise-left-controllers";
import { UpdateExerciseRightControllers } from "./controllers/update-exercise-right-controllers/update-exercise-right-controllers";
import {
  useExercise,
  useReloadExercise,
} from "../context/loadable-exercise-context";

export const UpdateExercise = () => {
  const { exerciseDTO } = useExercise();

  const updateExerciseFormMethods = useForm<UpdateExerciseInputs>({
    defaultValues: {
      name: exerciseDTO.name,
      durationInMins: exerciseDTO.durationInMins,
      description: exerciseDTO.description,
      thumbnailUploadedFileId: exerciseDTO.thumbnailUploadedFileId,
      mediaUploadedFileId: exerciseDTO.mediaUploadedFileId,
      burnedKCals: exerciseDTO.burnedKCals,
      restrictions: exerciseDTO.restrictions,
    },
  });
  const navigate = useNavigate();

  const putExercise = usePutExercise();
  const { showToast } = useToastService();
  const { setExerciseState } = useExerciseState();
  const reloadExercise = useReloadExercise();

  const onSubmit = updateExerciseFormMethods.handleSubmit(async (inputs) => {
    try {
      const putExerciseDTO: PutExerciseDTO = {
        name: inputs.name,
        burnedKCals: Number(inputs.burnedKCals),
        description: inputs.description,
        durationInMins: Number(inputs.durationInMins),
        thumbnailUploadedFileId: inputs.thumbnailUploadedFileId!,
        mediaUploadedFileId: inputs.mediaUploadedFileId!,
        restrictions: [],
      };

      await putExercise(exerciseDTO.id, putExerciseDTO).then((res) => {
        navigate(`/workouts/exercise/${res.id}`);
        setExerciseState("Read");
      });
      showToast("Success", "Success Updating Exercise");
      reloadExercise();
    } catch (error) {
      showToast("Error", "Failed to Update Exercise :(");
    }
  });

  return (
    <FormProvider {...updateExerciseFormMethods}>
      <form onSubmit={onSubmit}>
        <WorkoutsScreenHeader>
          <p>Exercise</p>
          <Button type="submit">Confirm</Button>
        </WorkoutsScreenHeader>
        <WorkoutsFormContainer>
          <WorkoutsFormControllersContainer>
            <UpdateExerciseLeftControllers />
            <UpdateExerciseRightControllers />
          </WorkoutsFormControllersContainer>
        </WorkoutsFormContainer>
      </form>
    </FormProvider>
  );
};
