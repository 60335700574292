import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { ImageUploadHelpersProvider } from "../../../../../components/image-upload/context/image-upload-helpers-context";
import { ContentModuleDTO } from "../../../../../models/content-module-dtos/content-module-dto";
import { ContentModuleModeProvider } from "../../../context/content-module-mode-context";
import { ContentScreenContainer } from "../../../styles/content-reusable-classes";
import { ContentCourseModuleModes } from "./content-course-module-modes";

type ContentCourseModuleBodyProps = {
  contentCourseModule?: ContentModuleDTO;
};

export const ContentCourseModuleBody = (
  props: ContentCourseModuleBodyProps
) => {
  const { contentCourseModule } = props;

  if (contentCourseModule === undefined) {
    return (
      <EmptyState
        button={<></>}
        hasHeader={false}
        hasSearch={false}
        text="Something went wrong please reload the page or try again"
        title=""
      />
    );
  }

  return (
    <ContentModuleModeProvider contentModuleDTO={contentCourseModule}>
      <ContentScreenContainer $lessonUI={false}>
        <ImageUploadHelpersProvider>
          <ContentCourseModuleModes />
        </ImageUploadHelpersProvider>
      </ContentScreenContainer>
    </ContentModuleModeProvider>
  );
};
