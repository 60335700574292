import { Link } from "react-router-dom";
import { EmptyState } from "../../../../../components/empty-state/empty-state";
import { Button } from "../../../../../styles/classes/reusable-classes";
import { useWorkoutProgram } from "../context/loadable-workout-program-context";
import { TableHeader } from "../../../../../components/table/table-header/table-header";
import { Table } from "../../../../../components/table/table";
import { useState } from "react";
import { LevelsTableRows } from "./levels-table/levels-table-rows";
import { LavelsTableHeadings } from "./levels-table/levels-table-headings";
import { WorkoutsSubTableContainer } from "../../../styles/workouts-common-styles";

export const Levels = () => {
  const { workoutProgramDTO } = useWorkoutProgram();

  const levelDTOs = workoutProgramDTO.workoutProgramLevels;

  const [searchTerm, setSearchTerm] = useState("");

  const onSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  if (levelDTOs?.length === 0 || !levelDTOs) {
    return (
      <EmptyState
        title=""
        hasHeader={false}
        hasSearch={false}
        text="No levels found for this program"
        button={
          <Link to={`/workouts/program/${workoutProgramDTO.id}/create-level`}>
            <Button>Add Level</Button>
          </Link>
        }
      />
    );
  }

  return (
    <WorkoutsSubTableContainer>
      <TableHeader
        title="All Levels"
        searchTerm={searchTerm}
        setSearchTerm={onSearchTermChange}
        boldTitle
        button={
          <Link to={`/workouts/program/${workoutProgramDTO.id}/create-level`}>
            <Button>Add Level</Button>
          </Link>
        }
      />
      <Table
        tableHeadings={<LavelsTableHeadings />}
        tableRows={<LevelsTableRows levelDTOs={levelDTOs} />}
      />
    </WorkoutsSubTableContainer>
  );
};
