import { useCallback, useEffect } from "react";
import { useImageUploadHelpers } from "../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { ImageUpload } from "../../../../../../../components/image-upload/image-upload";
import { useFormContext } from "react-hook-form";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { useAddUploadedFile } from "../../../../../../../hooks/uploaded-file-hooks/use-add-uploaded-file";
import { AddExerciseInputs } from "../../../types/add-exercise-inputs";
import { CreateUploadedFileDTO } from "../../../../../../../models/uploaded-file-dtos/create-uploaded-file-dto";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../styles/classes/reusable-classes";

export const ExerciseThumbnailContainer = () => {
  const { showToast } = useToastService();
  const addUploadedFile = useAddUploadedFile();

  const {
    imageFile,
    setLoading,
    handleUploadedImageState,
    imageUploadState,
  } = useImageUploadHelpers();

  const { setValue } = useFormContext<AddExerciseInputs>();

  const onUpload = useCallback(async () => {
    try {
      if (imageFile) {
        const createUploadedFileDTO: CreateUploadedFileDTO = {
          feature: "ContentModuleThumbnail",
        };

        setLoading(true);
        await addUploadedFile(
          createUploadedFileDTO,
          imageFile
        ).then((response) => setValue("thumbnailUploadedFileId", response.id));
        handleUploadedImageState(imageFile);
        showToast("Success", "Thumbnail Uploaded Successfully");
        setLoading(false);
      }
    } catch (error) {
      showToast("Error", "Failed to Upload Thumbnail :(");
    }
  }, [
    imageFile,
    setLoading,
    setValue,
    showToast,
    addUploadedFile,
    handleUploadedImageState,
  ]);

  useEffect(() => {
    if (imageFile && imageUploadState.state === "Upload") {
      onUpload();
    }
  }, [imageFile, onUpload, imageUploadState]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px" $isOptional>
        Exercise Thumbnail
        <span>(Optional)</span>
      </UXInputLabel>
      <ImageUpload />
    </UXInputContainer>
  );
};
