import { ImageUploadHelpersProvider } from "../../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { UpdateExerciseThumbnailContainer } from "./update-exercise-thumbnail-container";

export const UpdateExerciseThumbnailController = () => {
  return (
    <ImageUploadHelpersProvider>
      <UpdateExerciseThumbnailContainer />
    </ImageUploadHelpersProvider>
  );
};
