import {
  ChangeEvent,
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";

type WorkoutExercisesModalAutoSelectSearchTerm = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  handleOnSearchInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const WorkoutExercisesModalAutoSelectSearchTermContext = createContext<
  WorkoutExercisesModalAutoSelectSearchTerm | undefined
>(undefined);

type WorkoutExercisesModalAutoSelectSearchTermProviderProps = PropsWithChildren<{}>;

export function WorkoutExercisesModalAutoSelectSearchTermProvider(
  props: WorkoutExercisesModalAutoSelectSearchTermProviderProps
) {
  const { children } = props;

  const [searchTerm, setSearchTerm] = useState("");

  const handleOnSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value),
    []
  );

  return (
    <WorkoutExercisesModalAutoSelectSearchTermContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        handleOnSearchInputChange,
      }}
    >
      {children}
    </WorkoutExercisesModalAutoSelectSearchTermContext.Provider>
  );
}

export function useWorkoutExercisesModalAutoSelectSearchTerm(): WorkoutExercisesModalAutoSelectSearchTerm {
  const context = useContext(WorkoutExercisesModalAutoSelectSearchTermContext);

  if (!context) {
    throw new Error(
      "useWorkoutExercisesModalAutoSelectSearchTerm must be used within a WorkoutExercisesModalAutoSelectSearchTermProvider"
    );
  }

  return context;
}
