import { Controller, useFormContext } from "react-hook-form";
import {
  UXInputContainer,
  UXInputLabel,
  UXInputTextarea,
  UXInputWrapper,
} from "../../../../../../../styles/classes/reusable-classes";
import { UpdateExerciseInputs } from "../../types/update-exercise-inputs";

export const UpdateExerciseDescriptionController = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<UpdateExerciseInputs>();

  return (
    <Controller
      control={control}
      name="description"
      rules={{ required: true }}
      render={({ field: { value, onChange } }) => (
        <UXInputContainer $textareaDimentions>
          <UXInputLabel $width="150px">Description</UXInputLabel>
          <UXInputWrapper
            $textareaDimentions
            $width="80%"
            $errorState={errors.description?.type === "required"}
          >
            <UXInputTextarea
              placeholder="Write a description for this exercise..."
              dir="auto"
              value={value === undefined ? "" : value}
              onChange={(e) =>
                onChange(e.target.value === undefined ? "" : e.target.value)
              }
            />
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
