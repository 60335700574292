import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../styles/classes/reusable-classes";
import { useExercise } from "../../../context/loadable-exercise-context";

export const ReadExerciseDurationMinsController = () => {
  const { exerciseDTO } = useExercise();

  return (
    <UXInputContainer>
      <UXInputLabel $width="150px">Duration Mins</UXInputLabel>
      <UXInputWrapper $width="80%" $readOnly>
        <UXInputInput
          readOnly
          defaultValue={exerciseDTO.durationInMins}
          dir="auto"
        />
      </UXInputWrapper>
    </UXInputContainer>
  );
};
