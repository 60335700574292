import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  PropsWithChildren,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Temporal } from "temporal-polyfill";
import { generateDateRange } from "../../../../../components/date-range/utils/generate-date-range";
import { getDatesInRange } from "../../../../../components/date-range/utils/get-dates-in-range";
import { useCompareDateRangeParams } from "./compare-date-range-search-params-context";
import { DatesWithinRange } from "../../../../../components/date-range/context/date-range-context";

export type CompareSide = "Right" | "Left";

type DateRangePickerRef = RefObject<DateRangePickerComponent>;

export type SelectedDates = {
  temporalStartDate: string;
  temporalEndDate: string;
  count: number;
  datesWithinRange: DatesWithinRange[];
};

type CompareDateRange = {
  temporalStartDate: {
    left: string;
    right: string;
  };
  temporalEndDate: {
    left: string;
    right: string;
  };
  onDatesChange: (e: any, compareSide: CompareSide) => void;
  handleIncrementDates: (compareSide: CompareSide) => void;
  handleDecrementDates: (compareSide: CompareSide) => void;
  selectedDates: {
    left: SelectedDates;
    right: SelectedDates;
  };
  dateRangePickerRef: DateRangePickerRef;
};

const CompareDateRangeContext = createContext<CompareDateRange | undefined>(
  undefined
);

type DateRangeProviderProps = PropsWithChildren<{}>;

export function CompareDateRangeProvider(props: DateRangeProviderProps) {
  const { children } = props;

  const { searchParams, setSearchParams } = useCompareDateRangeParams();

  const dateRangePickerRef: DateRangePickerRef = useRef<
    DateRangePickerComponent
  >(null);

  let currentDate = useMemo(() => new Date(), []);
  let twoWeeksAgo = useMemo(
    () => new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000),
    [currentDate]
  );

  const searchParamsLeftStartDate = searchParams.get("leftStartDate");
  const searchParamsLeftEndDate = searchParams.get("leftEndDate");

  const searchParamsRightStartDate = searchParams.get("rightStartDate");
  const searchParamsRightEndDate = searchParams.get("rightEndDate");

  const [startDate, setStartDate] = useState({
    left: searchParamsLeftStartDate
      ? new Date(searchParamsLeftStartDate)
      : twoWeeksAgo,
    right: searchParamsRightStartDate
      ? new Date(searchParamsRightStartDate)
      : twoWeeksAgo,
  });

  const [endDate, setEndDate] = useState({
    left: searchParamsLeftEndDate
      ? new Date(searchParamsLeftEndDate)
      : currentDate,
    right: searchParamsRightEndDate
      ? new Date(searchParamsRightEndDate)
      : currentDate,
  });

  const temporalStartDate = useMemo(
    () => ({
      left: Temporal.PlainDate.from(
        startDate.left.toISOString().split("T")[0]
      ).toString(),
      right: Temporal.PlainDate.from(
        startDate.right.toISOString().split("T")[0]
      ).toString(),
    }),
    [startDate]
  );

  const temporalEndDate = useMemo(
    () => ({
      left: Temporal.PlainDate.from(
        endDate.left.toISOString().split("T")[0]
      ).toString(),
      right: Temporal.PlainDate.from(
        endDate.right.toISOString().split("T")[0]
      ).toString(),
    }),
    [endDate]
  );

  useEffect(() => {
    searchParams.set("rightStartDate", temporalStartDate.right);
    searchParams.set("rightEndDate", temporalEndDate.right);
    searchParams.set("leftStartDate", temporalStartDate.left);
    searchParams.set("leftEndDate", temporalEndDate.left);
    setSearchParams(searchParams, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDatesChange = useCallback(
    (e: any, compareSide: CompareSide) => {
      if (e.target.value !== null) {
        if (compareSide === "Right") {
          setStartDate({ ...startDate, right: e.target.value[0] });
          setEndDate({ ...endDate, right: e.target.value[1] });

          searchParams.set(
            "rightStartDate",
            e.target.value[0].toISOString().split("T")[0]
          );
          searchParams.set(
            "rightEndDate",
            e.target.value[1].toISOString().split("T")[0]
          );
          setSearchParams(searchParams, { replace: true });
        } else {
          setStartDate({ ...startDate, left: e.target.value[0] });
          setEndDate({ ...endDate, left: e.target.value[1] });

          searchParams.set(
            "leftStartDate",
            e.target.value[0].toISOString().split("T")[0]
          );
          searchParams.set(
            "leftEndDate",
            e.target.value[1].toISOString().split("T")[0]
          );
          setSearchParams(searchParams, { replace: true });
        }
      }
    },
    [
      startDate,
      endDate,
      setStartDate,
      setEndDate,
      searchParams,
      setSearchParams,
    ]
  );

  const handleIncrementDates = useCallback(
    (compareSide: CompareSide) => {
      if (compareSide === "Right") {
        const newStartDate = new Date(endDate.right);
        newStartDate.setDate(newStartDate.getDate() + 1);
        setStartDate({ ...startDate, right: newStartDate });

        const newEndDate = new Date(newStartDate);
        newEndDate.setDate(
          newEndDate.getDate() +
            generateDateRange(startDate.right, endDate.right).numberOfDays
        );
        setEndDate({ ...endDate, right: newEndDate });

        searchParams.set(
          "rightStartDate",
          newStartDate.toISOString().split("T")[0]
        );
        searchParams.set(
          "rightEndDate",
          newEndDate.toISOString().split("T")[0]
        );
        setSearchParams(searchParams, { replace: true });
      } else {
        const newStartDate = new Date(endDate.left);
        newStartDate.setDate(newStartDate.getDate() + 1);
        setStartDate({ ...startDate, left: newStartDate });

        const newEndDate = new Date(newStartDate);
        newEndDate.setDate(
          newEndDate.getDate() +
            generateDateRange(startDate.left, endDate.left).numberOfDays
        );
        setEndDate({ ...endDate, left: newEndDate });

        searchParams.set(
          "leftStartDate",
          newStartDate.toISOString().split("T")[0]
        );
        searchParams.set("leftEndDate", newEndDate.toISOString().split("T")[0]);
        setSearchParams(searchParams, { replace: true });
      }
    },
    [
      setStartDate,
      setEndDate,
      endDate,
      startDate,
      searchParams,
      setSearchParams,
    ]
  );

  const handleDecrementDates = useCallback(
    (compareSide: CompareSide) => {
      if (compareSide === "Right") {
        const newEndDate = new Date(startDate.right);
        newEndDate.setDate(newEndDate.getDate() - 1);
        setEndDate({ ...endDate, right: newEndDate });

        const newStartDate = new Date(newEndDate);
        newStartDate.setDate(
          newStartDate.getDate() -
            generateDateRange(startDate.right, endDate.right).numberOfDays
        );
        setStartDate({ ...startDate, right: newStartDate });

        searchParams.set(
          "rightStartDate",
          newStartDate.toISOString().split("T")[0]
        );
        searchParams.set(
          "rightEndDate",
          newEndDate.toISOString().split("T")[0]
        );
        setSearchParams(searchParams, { replace: true });
      } else {
        const newEndDate = new Date(startDate.left);
        newEndDate.setDate(newEndDate.getDate() - 1);
        setEndDate({ ...endDate, left: newEndDate });

        const newStartDate = new Date(newEndDate);
        newStartDate.setDate(
          newStartDate.getDate() -
            generateDateRange(startDate.left, endDate.left).numberOfDays
        );
        setStartDate({ ...startDate, left: newStartDate });

        searchParams.set(
          "leftStartDate",
          newStartDate.toISOString().split("T")[0]
        );
        searchParams.set("leftEndDate", newEndDate.toISOString().split("T")[0]);
        setSearchParams(searchParams, { replace: true });
      }
    },
    [
      setStartDate,
      setEndDate,
      endDate,
      startDate,
      searchParams,
      setSearchParams,
    ]
  );

  const selectedDates = useMemo(
    () => ({
      left: {
        temporalStartDate: temporalStartDate.left,
        temporalEndDate: temporalEndDate.left,
        datesWithinRange:
          startDate.left && endDate.left
            ? getDatesInRange(startDate.left, endDate.left)
            : [],
        count:
          startDate.left && endDate.left
            ? generateDateRange(startDate.left, endDate.left).numberOfDays + 1
            : 0,
      },
      right: {
        temporalStartDate: temporalStartDate.right,
        temporalEndDate: temporalEndDate.right,
        datesWithinRange: getDatesInRange(startDate.right, endDate.right),
        count:
          generateDateRange(startDate.right, endDate.right).numberOfDays + 1,
      },
    }),
    [temporalStartDate, temporalEndDate, startDate, endDate]
  );

  const value: CompareDateRange = useMemo(
    () => ({
      temporalStartDate,
      temporalEndDate,
      onDatesChange,
      handleIncrementDates,
      handleDecrementDates,
      selectedDates,
      dateRangePickerRef,
    }),
    [
      temporalStartDate,
      temporalEndDate,
      onDatesChange,
      handleIncrementDates,
      handleDecrementDates,
      selectedDates,
      dateRangePickerRef,
    ]
  );

  return (
    <CompareDateRangeContext.Provider value={value}>
      {children}
    </CompareDateRangeContext.Provider>
  );
}

export function useCompareDateRange(): CompareDateRange {
  const compareDateRange = useContext(CompareDateRangeContext);

  if (compareDateRange === undefined) {
    throw new Error(
      `useCompareDateRange must be used within CompareDateRangeProvider`
    );
  }

  return compareDateRange;
}
