import { useCallback } from "react";
import { useGetAllExercises } from "../../../../../../hooks/exercises/use-get-all-exercises";
import { LoadableWorkoutsProvider } from "./context/loadable-workouts-context";
import { WorkoutsContainer } from "./workouts-container/workouts-container";
import { useLoadableData } from "../../../../../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../../../../../components/loadable-data-loading";

export const Workouts = () => {
  const getAllExercises = useGetAllExercises();

  const loadWorkoutsCallback = useCallback(async () => {
    const [exerciseDTOs] = await Promise.all([getAllExercises()]);

    return { exerciseDTOs };
  }, [getAllExercises]);

  const loadableWorkouts = useLoadableData(loadWorkoutsCallback);

  return (
    <LoadableDataLoading
      state={loadableWorkouts.state}
      successComponent={() => (
        <LoadableWorkoutsProvider loadableWorkouts={loadableWorkouts}>
          <WorkoutsContainer />
        </LoadableWorkoutsProvider>
      )}
    />
  );
};
