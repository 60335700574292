import { useCallback } from "react";
import { DeleteModal } from "../../../../components/delete-modal/delete-modal";
import { GenericActionsListItemItem } from "../../../../components/generic-actions-list/styled-generic-actions-list";
import { Modal } from "../../../../components/modal/modal";
import { useModalHelpers } from "../../../../components/modal/use-modal-helpers";
import { useToastService } from "../../../../context/toast-service-context";
import { useDeleteExercise } from "../../../../hooks/exercises/use-delete-exercise";
import { ExerciseDTO } from "../../../../models/exercises-dtos/exercise-dto";
import { useReloadExercises } from "../context/loadable-exercises-context";

type DeleteExerciseModalProps = {
  exercise: ExerciseDTO;
};

export const DeleteExerciseModal = (props: DeleteExerciseModalProps) => {
  const { exercise } = props;

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  const deleteExercise = useDeleteExercise();

  const { showToast } = useToastService();
  const reloadExercises = useReloadExercises();

  const onDelete = useCallback(async () => {
    try {
      await deleteExercise(exercise.id);

      showToast("Success", "Exercise Deleted Successfully!");
      closeModal();
      reloadExercises();
    } catch (error) {
      showToast("Error", "Failed to Delete Exercise :(");
    }
  }, [exercise.id, closeModal, deleteExercise, reloadExercises, showToast]);

  return (
    <>
      <GenericActionsListItemItem isDanger onClick={openModal}>
        Delete
      </GenericActionsListItemItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <DeleteModal
          key={modalBodyKey}
          title={`Exercise ${exercise.name}`}
          onDelete={onDelete}
          onModalClose={closeModal}
        />
      </Modal>
    </>
  );
};
