import { useEffect } from "react";
import { useImageUploadHelpers } from "../../../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { useWorkoutProgram } from "../../../../../context/loadable-workout-program-context";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../../styles/classes/reusable-classes";
import { ImageUpload } from "../../../../../../../../../components/image-upload/image-upload";
import { useParams } from "react-router-dom";

export const ReadLevelCoverPhotoContainer = () => {
  const { workoutProgramDTO } = useWorkoutProgram();
  const { levelID } = useParams();

  const currentLevel = workoutProgramDTO.workoutProgramLevels?.find(
    (item) => item.id === parseInt(levelID!)
  );
  const coverPhotoUploadedFile = currentLevel?.coverPhotoUploadedFile;

  const { setImageFile, setImageUploadState } = useImageUploadHelpers();

  useEffect(() => {
    if (coverPhotoUploadedFile) {
      setImageUploadState({
        state: "Uploaded",
        previewUrl: coverPhotoUploadedFile.presignedUrl,
      });

      const jsonCoverPhotoUploadedFile = JSON.stringify(coverPhotoUploadedFile);
      const blob = new Blob([jsonCoverPhotoUploadedFile], {
        type: "application/json",
      });
      setImageFile(blob);
    }
  }, [setImageUploadState, coverPhotoUploadedFile, setImageFile]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px" $isOptional>
        Level CoverPhoto
        <span>(Optional)</span>
      </UXInputLabel>
      <ImageUpload readOnly uploadText="Cover Photo" />
    </UXInputContainer>
  );
};
