import { WorkoutsFormFormSubContainer } from "../../../../../styles/workouts-common-styles";
import { UpdateExerciseMediaController } from "./update-exercise-media-controller/update-exercise-media-controller";
import { UpdateExerciseThumbnailController } from "./update-exercise-thumbnail-controller/update-exercise-thumbnail-controller";

export const UpdateExerciseRightControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <UpdateExerciseThumbnailController />
      <UpdateExerciseMediaController />
    </WorkoutsFormFormSubContainer>
  );
};
