import { Button } from "../../../../../styles/classes/reusable-classes";
import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
  WorkoutsScreenHeader,
} from "../../../styles/workouts-common-styles";
import { useExerciseState } from "../context/exercise-state-context";
import { ReadExerciseLeftControllers } from "./controllers/read-exercise-left-controllers/read-exercise-left-controllers";
import { ReadExerciseRightControllers } from "./controllers/read-exercise-right-controllers/read-exercise-right-controllers";

export const ReadExerciseForm = () => {
  const { setExerciseState } = useExerciseState();

  return (
    <div>
      <WorkoutsScreenHeader>
        <p>Exercise</p>
        <Button type="button" onClick={() => setExerciseState("Update")}>
          Edit
        </Button>
      </WorkoutsScreenHeader>
      <WorkoutsFormContainer>
        <WorkoutsFormControllersContainer>
          <ReadExerciseLeftControllers />
          <ReadExerciseRightControllers />
        </WorkoutsFormControllersContainer>
      </WorkoutsFormContainer>
    </div>
  );
};
