import { FormProvider, useForm, UseFormSetValue } from "react-hook-form";
import { Modal } from "../../../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../../../components/modal/use-modal-helpers";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";
import { AddLevelInputs } from "../../../add-level/types/add-level-inputs";
import { WorkoutsExercisesModalBody } from "./workouts-exercises-modal-body/workouts-exercises-modal-body";
import { WorkoutExercisesInputs } from "./types/workout-exercises-inputs";
import { CreateWorkoutDTO } from "../../../../../../../../models/workout-programs-dtos/create-workout-program-dtos/create-workout-dto";

type WorkoutsExercisesModalProps = {
  day: number;
  setWorkoutsValue: UseFormSetValue<AddLevelInputs>;
  workoutsWatcher: CreateWorkoutDTO[] | undefined;
};

export const WorkoutsExercisesModal = (props: WorkoutsExercisesModalProps) => {
  const { day, setWorkoutsValue, workoutsWatcher } = props;

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  const workoutExercisesFormMethods = useForm<WorkoutExercisesInputs>();

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          openModal();
          workoutExercisesFormMethods.setValue("dayNumber", day);
        }}
      >
        Workout Day
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FormProvider {...workoutExercisesFormMethods}>
          <WorkoutsExercisesModalBody
            key={modalBodyKey}
            closeModal={closeModal}
            day={day}
            setWorkoutsValue={setWorkoutsValue}
            workoutsWatcher={workoutsWatcher}
          />
        </FormProvider>
      </Modal>
    </>
  );
};
