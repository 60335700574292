import { useCallback } from "react";
import {
  WorkoutsExercisesContainer,
  WorkoutsExercisesHeader,
} from "./styled-workouts-exercises-modal-body";
import { ModalForm } from "../../../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../../../../styles/classes/reusable-classes";
import { WorkoutsExercisesModalAutoSelect } from "./workouts-exercises-modal-auto-select/workouts-exercises-modal-auto-select";
import { ExerciseDTO } from "../../../../../../../../../models/exercises-dtos/exercise-dto";
import { WorkoutExercisesModalAutoSelectSearchTermProvider } from "./workouts-exercises-modal-auto-select/context/workout-exercises-modal-auto-select-search-term-context";
import {
  useFieldArray,
  useFormContext,
  UseFormSetValue,
} from "react-hook-form";
import { AddLevelInputs } from "../../../../add-level/types/add-level-inputs";
import { WorkoutsExercisesModalBodyNameController } from "./controllers/workouts-exercises-modal-body-name-controller";
import { WorkoutsExercisesModalBodyDescriptionController } from "./controllers/workouts-exercises-modal-body-description-controller";
import { WorkoutsExercisesModalBodyList } from "./workouts-exercises-modal-body-list/workouts-exercises-modal-body-list";
import { WorkoutExercisesInputs } from "../types/workout-exercises-inputs";
import { CreateWorkoutDTO } from "../../../../../../../../../models/workout-programs-dtos/create-workout-program-dtos/create-workout-dto";

type WorkoutsExercisesModalBodyProps = {
  closeModal: () => void;
  day: number;
  setWorkoutsValue: UseFormSetValue<AddLevelInputs>;
  workoutsWatcher: CreateWorkoutDTO[] | undefined;
  editMode?: boolean;
};

export const WorkoutsExercisesModalBody = (
  props: WorkoutsExercisesModalBodyProps
) => {
  const {
    closeModal,
    day,
    setWorkoutsValue,
    workoutsWatcher,
    editMode,
  } = props;

  const { control, watch } = useFormContext<WorkoutExercisesInputs>();
  const { append, move, remove } = useFieldArray<WorkoutExercisesInputs>({
    control,
    name: `workoutExercises`,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const workoutFields = {
    dayNumber: watch("dayNumber"),
    name: watch("name"),
    description: watch("description"),
    workoutExercises: watch("workoutExercises"),
  };

  const handleAppendWorkouts = useCallback(() => {
    if (workoutsWatcher) {
      const updatedWorkouts = workoutsWatcher.map((workout) =>
        workout.dayNumber === workoutFields.dayNumber
          ? { ...workout, ...workoutFields } // Update the existing workout
          : workout
      );

      setWorkoutsValue(
        "workouts",
        editMode ? updatedWorkouts : [...workoutsWatcher, workoutFields]
      );
    } else {
      setWorkoutsValue("workouts", [workoutFields]);
    }
  }, [workoutFields, workoutsWatcher, setWorkoutsValue, editMode]);

  const handleAppendWorkoutExercises = useCallback(
    (exerciseDTO: ExerciseDTO, index: number) => {
      if (
        !workoutFields.workoutExercises?.some(
          (watcherItem) => watcherItem.exerciseId === exerciseDTO.id
        )
      ) {
        append({
          exerciseId: exerciseDTO.id,
          uiOrder: index + 1,
        });
      }
    },
    [append, workoutFields.workoutExercises]
  );

  return (
    <ModalForm width={800} height={800} onSubmit={(e) => e.preventDefault()}>
      <ModalHeader
        onModalClose={closeModal}
        title={
          editMode
            ? `Edit exercises for Day ${day}`
            : `Add exercises for Day ${day}`
        }
      />
      <WorkoutsExercisesContainer>
        <WorkoutsExercisesHeader>
          <WorkoutsExercisesModalBodyNameController />
          <WorkoutsExercisesModalBodyDescriptionController />
          <WorkoutExercisesModalAutoSelectSearchTermProvider>
            <WorkoutsExercisesModalAutoSelect
              append={handleAppendWorkoutExercises}
            />
          </WorkoutExercisesModalAutoSelectSearchTermProvider>
        </WorkoutsExercisesHeader>
        <WorkoutsExercisesModalBodyList move={move} remove={remove} />
      </WorkoutsExercisesContainer>
      <ModalFooter>
        <Button outlined type="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="button"
          onClick={() => {
            handleAppendWorkouts();
            closeModal();
          }}
        >
          {editMode ? "Update" : "Confirm"}
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
