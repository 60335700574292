import { useProgramState } from "./context/program-state-context";
import { ReadProgramForm } from "./read-program-form/read-program-form";
import { UpdateProgramForm } from "./update-program-form/update-program-form";

export const ProgramState = () => {
  const { programState } = useProgramState();

  if (programState === "Read") {
    return <ReadProgramForm />;
  }

  return <UpdateProgramForm />;
};
