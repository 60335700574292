import { ReadLevelForm } from "./read-level-form/read-level-form";
import { useLevelState } from "./context/level-state-context";
import { UpdateLevelForm } from "./update-level-form/update-level-form";

export const LevelState = () => {
  const { levelState } = useLevelState();

  if (levelState === "Read") {
    return <ReadLevelForm />;
  }

  return <UpdateLevelForm />;
};
