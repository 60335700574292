import { createContext, PropsWithChildren, useContext } from "react";
import { UseLoadableDataReturn } from "../../../../hooks/use-loadable-data";
import { ExerciseDTO } from "../../../../models/exercises-dtos/exercise-dto";

export type Exercises = {
  exercises: ExerciseDTO[];
};

export type LoadableExercises = UseLoadableDataReturn<Exercises>;

const LoadableExercisesContext = createContext<LoadableExercises | undefined>(
  undefined
);

export type LoadableExercisesProviderProps = PropsWithChildren<{
  loadableExercises: LoadableExercises;
}>;

export function LoadableExercisesProvider(
  props: LoadableExercisesProviderProps
) {
  const { loadableExercises, children } = props;

  return (
    <LoadableExercisesContext.Provider value={loadableExercises}>
      {children}
    </LoadableExercisesContext.Provider>
  );
}

export function useLoadableExercises(): LoadableExercises {
  const loadableExercises = useContext(LoadableExercisesContext);

  if (loadableExercises === undefined) {
    throw Error(
      "useLoadableExercises must be used within an LoadableExercisesProvider"
    );
  }

  return loadableExercises;
}

export function useExercises(): Exercises {
  const loadableExercises = useLoadableExercises();

  if (loadableExercises.state.data === undefined) {
    throw Error("Exercises have never been loaded successfully yet.");
  }

  return loadableExercises.state.data;
}

export function useReloadExercises(): () => void {
  const loadableExercises = useLoadableExercises();

  return loadableExercises.reload;
}
