import { FormProvider, useForm, UseFormSetValue } from "react-hook-form";
import { Modal } from "../../../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../../../components/modal/use-modal-helpers";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";
import { WorkoutsExercisesModalBody } from "../workouts-exercises-modal/workouts-exercises-modal-body/workouts-exercises-modal-body";
import { WorkoutExercisesInputs } from "../workouts-exercises-modal/types/workout-exercises-inputs";
import { AddLevelInputs } from "../../../add-level/types/add-level-inputs";
import { CreateWorkoutDTO } from "../../../../../../../../models/workout-programs-dtos/create-workout-program-dtos/create-workout-dto";

type WorkoutsExercisesEditModalProps = {
  day: number;
  setWorkoutsValue: UseFormSetValue<AddLevelInputs>;
  workoutsWatcher: CreateWorkoutDTO[] | undefined;
};

export const WorkoutsExercisesEditModal = (
  props: WorkoutsExercisesEditModalProps
) => {
  const { day, setWorkoutsValue, workoutsWatcher } = props;

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  const currentWorkout = workoutsWatcher?.find(
    (workoutDTO) => workoutDTO.dayNumber === day
  );

  const workoutExercisesFormMethods = useForm<WorkoutExercisesInputs>({
    defaultValues: {
      dayNumber: day,
      name: currentWorkout?.name,
      description: currentWorkout?.description,
      workoutExercises: currentWorkout?.workoutExercises,
    },
  });

  return (
    <>
      <Button type="button" flex onClick={openModal}>
        <span className="material-symbols-outlined">open_in_full</span>Expand
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FormProvider {...workoutExercisesFormMethods}>
          <WorkoutsExercisesModalBody
            key={modalBodyKey}
            closeModal={closeModal}
            day={day}
            setWorkoutsValue={setWorkoutsValue}
            workoutsWatcher={workoutsWatcher}
            editMode
          />
        </FormProvider>
      </Modal>
    </>
  );
};
