import { FormProvider, useForm } from "react-hook-form";
import { ContentScreenContainer } from "../../styles/content-reusable-classes";
import { CreateCourseHeader } from "./create-course-header";
import { CreateCourseControllers } from "./create-course-controllers/create-course-controllers";
import { useReloadContent } from "../../context/loadable-content-context";
import { useAddContentModule } from "../../../../hooks/content-module-hooks/use-add-content-module";
import { useToastService } from "../../../../context/toast-service-context";
import { CreateContentModuleDTO } from "../../../../models/content-module-dtos/create-content-module-dto";
import { useNavigate } from "react-router-dom";
import { TargetAudienceDTO } from "../../../../models/target-audience-dtos/target-audience-dto";
import { ImageUploadHelpersProvider } from "../../../../components/image-upload/context/image-upload-helpers-context";

export type CreateCourseInputs = {
  name?: string;
  description?: string;
  objective?: string;
  status: boolean;
  thumbnailUploadedFileId?: number;
  targetAudience: TargetAudienceDTO[];
};

export const CreateCourse = () => {
  const createCourseFormMethods = useForm<CreateCourseInputs>({
    defaultValues: { status: false, targetAudience: [] },
  });

  const { showToast } = useToastService();
  const addContentModule = useAddContentModule();
  const reloadContent = useReloadContent();
  const navigate = useNavigate();

  const onSubmit = createCourseFormMethods.handleSubmit(async (inputs) => {
    try {
      const targetAudience = inputs.targetAudience;

      const createContentModuleDTO: CreateContentModuleDTO = {
        contentModuleType: "Course",
        isDraft: inputs.status === true ? false : true,
        name: inputs.name!,
        description: inputs.description,
        objective: inputs.objective,
        thumbnailUploadedFileId: inputs.thumbnailUploadedFileId,
        targetAudienceIds:
          targetAudience.length > 0
            ? targetAudience.map((audience) => audience.id)
            : [],
        parentContentModuleId: undefined,
        childContentModuleIds: [],
        contentModuleCards: [],
      };

      await addContentModule(createContentModuleDTO).then((response) =>
        navigate(`../courses/course/${response.id}`)
      );
      showToast("Success", "Course Added Successfully!");
      reloadContent();
    } catch (error) {
      showToast("Error", "Faild to Add Course :(");
    }
  });

  return (
    <FormProvider {...createCourseFormMethods}>
      <ContentScreenContainer $lessonUI={false}>
        <form onSubmit={onSubmit}>
          <ImageUploadHelpersProvider>
            <CreateCourseHeader />
            <CreateCourseControllers />
          </ImageUploadHelpersProvider>
        </form>
      </ContentScreenContainer>
    </FormProvider>
  );
};
