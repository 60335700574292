import { WorkoutsFormFormSubContainer } from "../../../../../../styles/workouts-common-styles";
import { AddLevelDaysCountController } from "./add-level-days-count-controller";
import { AddLevelNameController } from "./add-level-name-controller";
import { AddLevelUiOrder } from "./add-level-ui-order";

export const AddLevelLeftControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <AddLevelNameController />
      <AddLevelDaysCountController />
      <AddLevelUiOrder />
    </WorkoutsFormFormSubContainer>
  );
};
