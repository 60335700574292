import { Controller, useFormContext } from "react-hook-form";
import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../styles/classes/reusable-classes";
import { AddExerciseInputs } from "../../types/add-exercise-inputs";

export const ExerciseDurationMinsController = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AddExerciseInputs>();

  return (
    <Controller
      control={control}
      name="durationInMins"
      rules={{ required: true }}
      render={({ field: { value, onChange } }) => (
        <UXInputContainer>
          <UXInputLabel $width="150px">Duration Mins</UXInputLabel>
          <UXInputWrapper
            $width="80%"
            $errorState={errors.durationInMins?.type === "required"}
          >
            <UXInputInput
              placeholder="Enter exercise duration in minutes"
              value={value === undefined ? "" : value}
              dir="auto"
              onChange={(e) =>
                onChange(
                  e.target.value === undefined ? undefined : e.target.value
                )
              }
            />
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
