import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../styles/classes/reusable-classes";
import { useWorkoutProgram } from "../../../context/loadable-workout-program-context";

export const ReadProgramNameController = () => {
  const { workoutProgramDTO } = useWorkoutProgram();

  return (
    <UXInputContainer>
      <UXInputLabel $width="150px">Program Name</UXInputLabel>
      <UXInputWrapper $width="80%" $readOnly>
        <UXInputInput
          width={"100%"}
          dir="auto"
          type="text"
          readOnly
          defaultValue={workoutProgramDTO.name}
        />
      </UXInputWrapper>
    </UXInputContainer>
  );
};
