import { Outlet } from "react-router-dom";
import { WorkoutsNavbar } from "./workouts-navbar/workouts-navbar";

export const Workouts = () => {
  return (
    <div>
      <WorkoutsNavbar />
      <Outlet />
    </div>
  );
};
