import { ImageUploadHelpersProvider } from "../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { ReadProgramCoverPhotoContainer } from "./read-program-cover-photo-container";

export const ReadProgramCoverPhotoController = () => {
  return (
    <ImageUploadHelpersProvider>
      <ReadProgramCoverPhotoContainer />
    </ImageUploadHelpersProvider>
  );
};
