import { WorkoutsFormFormSubContainer } from "../../../../styles/workouts-common-styles";
import { AddProgramDefaultController } from "./add-program-default-controller";
import { AddProgramDraftController } from "./add-program-draft-controller";
import { AddProgramNameController } from "./add-program-name-controller";

export const AddProgramLeftControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <AddProgramNameController />
      <AddProgramDraftController />
      <AddProgramDefaultController />
    </WorkoutsFormFormSubContainer>
  );
};
