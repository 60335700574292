import { ExerciseDTO } from "../../../../../../../../models/exercises-dtos/exercise-dto";

export function calculateWorkoutDetails(exerciseDTOs: ExerciseDTO[]) {
  const totalWorkoutDuration = exerciseDTOs.reduce(
    (sum: number, item: ExerciseDTO) => {
      return (sum += item.durationInMins);
    },
    0
  );

  const totalBurnedKcals = exerciseDTOs.reduce(
    (sum: number, item: ExerciseDTO) => {
      return (sum += item.burnedKCals);
    },
    0
  );

  return { totalWorkoutDuration, totalBurnedKcals };
}
