import { ImageUploadHelpersProvider } from "../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { ExerciseThumbnailContainer } from "./exercise-thumbnail-container";

export const ExerciseThumbnailController = () => {
  return (
    <ImageUploadHelpersProvider>
      <ExerciseThumbnailContainer />
    </ImageUploadHelpersProvider>
  );
};
