import { Controller, useFormContext } from "react-hook-form";
import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../styles/classes/reusable-classes";
import { UpdateProgramInputs } from "../../types/update-program-form-inputs";

export const UpdateProgramNameController = () => {
  const { control } = useFormContext<UpdateProgramInputs>();

  return (
    <Controller
      control={control}
      name="name"
      render={({ field: { value, onChange } }) => (
        <UXInputContainer>
          <UXInputLabel $width="150px">Program Name</UXInputLabel>
          <UXInputWrapper $width="80%">
            <UXInputInput
              width={"100%"}
              dir="auto"
              type="text"
              value={value !== undefined ? value : ""}
              onChange={(e) =>
                onChange(e.target.value !== undefined ? e.target.value : "")
              }
            />
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
