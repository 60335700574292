import { useEffect } from "react";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../styles/classes/reusable-classes";
import { UploadedFileWithPresignedUrlDTO } from "../../../../../../../../models/uploaded-file-dtos/uploaded-file-with-presigned-url-dto";
import { ImageUpload } from "../../../../../../../../components/image-upload/image-upload";
import { useImageUploadHelpers } from "../../../../../../../../components/image-upload/context/image-upload-helpers-context";

type LessonReadModeCardPartImageProps = {
  thumbnailUploadedFile?: UploadedFileWithPresignedUrlDTO;
};

export const LessonReadModeCardPartImage = (
  props: LessonReadModeCardPartImageProps
) => {
  const { thumbnailUploadedFile } = props;

  const { setImageFile, setImageUploadState } = useImageUploadHelpers();

  useEffect(() => {
    if (thumbnailUploadedFile) {
      setImageUploadState({
        state: "Uploaded",
        previewUrl: thumbnailUploadedFile.presignedUrl,
      });

      const jsonThumbnailUploadedFile = JSON.stringify(thumbnailUploadedFile);
      const blob = new Blob([jsonThumbnailUploadedFile], {
        type: "application/json",
      });
      setImageFile(blob);
    }
  }, [setImageUploadState, thumbnailUploadedFile, setImageFile]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="50px" $isOptional>
        Image
      </UXInputLabel>
      <ImageUpload readOnly />
    </UXInputContainer>
  );
};
