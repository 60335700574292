import { WorkoutsFormFormSubContainer } from "../../../../styles/workouts-common-styles";
import { ExerciseMediaController } from "./exercise-media-controller/exercise-media-controller";
import { ExerciseThumbnailController } from "./exercise-thumbnail-controller/exercise-thumbnail-controller";

export const AddExerciseRightControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <ExerciseThumbnailController />
      <ExerciseMediaController />
    </WorkoutsFormFormSubContainer>
  );
};
