import { createContext, PropsWithChildren, useContext, useState } from "react";

type State = "Read" | "Update";

type ExerciseState = {
  exerciseState: State;
  setExerciseState: (exerciseState: State) => void;
};

const ExerciseStateContext = createContext<ExerciseState | undefined>(
  undefined
);

type ExerciseStateProviderProps = PropsWithChildren<{}>;

export function ExerciseStateProvider(props: ExerciseStateProviderProps) {
  const { children } = props;

  const [exerciseState, setExerciseState] = useState<State>("Read");

  return (
    <ExerciseStateContext.Provider value={{ exerciseState, setExerciseState }}>
      {children}
    </ExerciseStateContext.Provider>
  );
}

export const useExerciseState = (): ExerciseState => {
  const context = useContext(ExerciseStateContext);

  if (!context) {
    throw new Error(
      "useExerciseState must be used within an ExerciseStateProvider"
    );
  }

  return context;
};
