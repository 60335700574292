import { useParams } from "react-router-dom";
import { useGetOneWorkoutProgram } from "../../../../../../hooks/workout-program-hooks/use-get-one-workout-program";
import { useCallback } from "react";
import { LoadableWorkoutProgramProvider } from "../../context/loadable-workout-program-context";
import { useLoadableData } from "../../../../../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../../../../../components/loadable-data-loading";
import { LevelStateProvider } from "./context/level-state-context";
import { LevelState } from "./level-state";

export const Level = () => {
  const { programID } = useParams();

  const getOneWorkoutProgram = useGetOneWorkoutProgram();

  const loadOneWorkoutProgram = useCallback(async () => {
    const id = parseInt(programID!);

    const [workoutProgramDTO] = await Promise.all([getOneWorkoutProgram(id)]);

    return { workoutProgramDTO };
  }, [getOneWorkoutProgram, programID]);

  const loadableWorkoutProgram = useLoadableData(loadOneWorkoutProgram);

  return (
    <LoadableDataLoading
      state={loadableWorkoutProgram.state}
      successComponent={() => (
        <LoadableWorkoutProgramProvider
          loadableWorkoutProgram={loadableWorkoutProgram}
        >
          <LevelStateProvider>
            <LevelState />
          </LevelStateProvider>
        </LoadableWorkoutProgramProvider>
      )}
    />
  );
};
