import { WorkoutsFormFormSubContainer } from "../../../../styles/workouts-common-styles";
import { ExerciseBurnedKcalsController } from "./exercise-burned-kcals-controller";
import { ExerciseDescriptionController } from "./exercise-description-controller";
import { ExerciseDurationMinsController } from "./exercise-duration-mins-controller";
import { ExerciseNameController } from "./exercise-name-controller";

export const AddExerciseLeftControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <ExerciseNameController />
      <ExerciseBurnedKcalsController />
      <ExerciseDurationMinsController />
      <ExerciseDescriptionController />
    </WorkoutsFormFormSubContainer>
  );
};
