import { useEffect } from "react";
import { useImageUploadHelpers } from "../../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { ImageUpload } from "../../../../../../../../components/image-upload/image-upload";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../styles/classes/reusable-classes";
import { useExercise } from "../../../../context/loadable-exercise-context";

export const ReadExerciseThumbnailContainer = () => {
  const { exerciseDTO } = useExercise();
  const thumbnailUploadedFile = exerciseDTO.thumbnailUploadedFile;

  const { setImageFile, setImageUploadState } = useImageUploadHelpers();

  useEffect(() => {
    if (thumbnailUploadedFile) {
      setImageUploadState({
        state: "Uploaded",
        previewUrl: thumbnailUploadedFile.presignedUrl,
      });

      const jsonThumbnailUploadedFile = JSON.stringify(thumbnailUploadedFile);
      const blob = new Blob([jsonThumbnailUploadedFile], {
        type: "application/json",
      });
      setImageFile(blob);
    }
  }, [setImageUploadState, thumbnailUploadedFile, setImageFile]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px">Exercise Thumbnail</UXInputLabel>
      <ImageUpload readOnly />
    </UXInputContainer>
  );
};
