import { useCallback, useEffect } from "react";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../styles/classes/reusable-classes";
import { useAddUploadedFile } from "../../../../../hooks/uploaded-file-hooks/use-add-uploaded-file";
import { ContentModuleDTO } from "../../../../../models/content-module-dtos/content-module-dto";
import { useFormContext } from "react-hook-form";
import { CreateUploadedFileDTO } from "../../../../../models/uploaded-file-dtos/create-uploaded-file-dto";
import { useToastService } from "../../../../../context/toast-service-context";
import { ContentModuleUpdateModeInputs } from "../../../context/content-module-mode-context";
import { useImageUploadHelpers } from "../../../../../components/image-upload/context/image-upload-helpers-context";
import { ImageUpload } from "../../../../../components/image-upload/image-upload";

type UpdateContentModuleThumbnailControllerProps = {
  contentModuleDTO: ContentModuleDTO;
};

export const UpdateContentModuleThumbnailController = (
  props: UpdateContentModuleThumbnailControllerProps
) => {
  const { contentModuleDTO } = props;

  const {
    imageFile,
    imageUploadState,
    setImageFile,
    setImageUploadState,
    handleUploadedImageState,
    setLoading,
  } = useImageUploadHelpers();

  const addUploadedFile = useAddUploadedFile();
  const { showToast } = useToastService();
  const { setValue } = useFormContext<ContentModuleUpdateModeInputs>();

  useEffect(() => {
    if (contentModuleDTO.thumbnailUploadedFile) {
      setImageUploadState({
        state: "Uploaded",
        previewUrl: contentModuleDTO.thumbnailUploadedFile.presignedUrl,
      });

      const jsonThumbnailUploadedFile = JSON.stringify(
        contentModuleDTO.thumbnailUploadedFile
      );
      const blob = new Blob([jsonThumbnailUploadedFile], {
        type: "application/json",
      });
      setImageFile(blob);
    }
  }, [
    setImageUploadState,
    contentModuleDTO.thumbnailUploadedFile,
    setImageFile,
  ]);

  const onUpload = useCallback(async () => {
    try {
      if (imageFile) {
        const createUploadedFileDTO: CreateUploadedFileDTO = {
          feature: "ContentModuleThumbnail",
        };

        setLoading(true);
        await addUploadedFile(
          createUploadedFileDTO,
          imageFile
        ).then((response) => setValue("thumbnailUploadedFileId", response.id));
        handleUploadedImageState(imageFile);
        showToast("Success", "Thumbnail Uploaded Successfully");
        setLoading(false);
      }
    } catch (error) {
      showToast("Error", "Failed to Upload Thumbnail :(");
    }
  }, [
    imageFile,
    setLoading,
    showToast,
    addUploadedFile,
    handleUploadedImageState,
    setValue,
  ]);

  useEffect(() => {
    if (imageFile && imageUploadState.state === "Upload") {
      onUpload();
    } else if (
      imageUploadState.state === "Upload" &&
      imageUploadState.previewUrl === undefined
    ) {
      setValue("thumbnailUploadedFileId", undefined);
    }
  }, [imageFile, onUpload, imageUploadState, setValue]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px" $isOptional>
        {contentModuleDTO.contentModuleType} Thumbnail
        <span>(Optional)</span>
      </UXInputLabel>
      <ImageUpload />
    </UXInputContainer>
  );
};
