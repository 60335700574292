import { Link, useLocation } from "react-router-dom";
import {
  WorkoutsNavbarLink,
  WorkoutsNavbarNav,
} from "./styled-workouts-navbar";

export const WorkoutsNavbar = () => {
  const location = useLocation();

  return (
    <WorkoutsNavbarNav>
      <WorkoutsNavbarLink $active={location.pathname.includes("programs")}>
        <Link to={`/workouts/programs/1`}>Programs</Link>
      </WorkoutsNavbarLink>
      <WorkoutsNavbarLink $active={location.pathname.includes("exercises")}>
        <Link to={`/workouts/exercises/1`}>Exercises</Link>
      </WorkoutsNavbarLink>
    </WorkoutsNavbarNav>
  );
};
