import { ImageUploadHelpersProvider } from "../../../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { CreateLessonCardPartsImageUpload } from "./create-lesson-card-parts-image-upload";

type CardImagePartProps = {
  cardIndex: number;
  cardPartIndex: number;
};

export const CardImagePart = (props: CardImagePartProps) => {
  const { cardIndex, cardPartIndex } = props;

  return (
    <ImageUploadHelpersProvider>
      <CreateLessonCardPartsImageUpload
        cardIndex={cardIndex}
        cardPartIndex={cardPartIndex}
      />
    </ImageUploadHelpersProvider>
  );
};
