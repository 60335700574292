import { ImageUploadHelpersProvider } from "../../../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { ReadLevelCoverPhotoContainer } from "./read-level-cover-photo-container";

export const ReadLevelCoverPhotoController = () => {
  return (
    <ImageUploadHelpersProvider>
      <ReadLevelCoverPhotoContainer />
    </ImageUploadHelpersProvider>
  );
};
