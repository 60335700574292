import { createContext, PropsWithChildren, useContext, useState } from "react";

type State = "Read" | "Update";

type ProgramState = {
  programState: State;
  setProgramState: (programState: State) => void;
};

const ProgramStateContext = createContext<ProgramState | undefined>(undefined);

type ProgramStateProviderProps = PropsWithChildren<{}>;

export function ProgramStateProvider(props: ProgramStateProviderProps) {
  const { children } = props;

  const [programState, setProgramState] = useState<State>("Read");

  return (
    <ProgramStateContext.Provider value={{ programState, setProgramState }}>
      {children}
    </ProgramStateContext.Provider>
  );
}

export const useProgramState = (): ProgramState => {
  const context = useContext(ProgramStateContext);

  if (!context) {
    throw new Error(
      "useProgramState must be used within an ProgramStateProvider"
    );
  }

  return context;
};
