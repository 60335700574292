import { ImageUploadHelpersProvider } from "../../../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { LessonUpdateModeCardImagePartController } from "./lesson-update-mode-card-image-part-controller";

type LessonUpdateModeCardImagePartProps = {
  cardIndex: number;
  cardPartIndex: number;
};

export const LessonUpdateModeCardImagePart = (
  props: LessonUpdateModeCardImagePartProps
) => {
  const { cardIndex, cardPartIndex } = props;

  return (
    <ImageUploadHelpersProvider>
      <LessonUpdateModeCardImagePartController
        cardIndex={cardIndex}
        cardPartIndex={cardPartIndex}
      />
    </ImageUploadHelpersProvider>
  );
};
