import { WorkoutsFormFormSubContainer } from "../../../../../styles/workouts-common-styles";
import { UpdateExerciseBurnedKcalsController } from "./update-exercise-burned-kcals-controller";
import { UpdateExerciseDescriptionController } from "./update-exercise-description-controller";
import { UpdateExerciseDurationMinsController } from "./update-exercise-duration-mins-controller";
import { UpdateExerciseNameController } from "./update-exercise-name-controller";

export const UpdateExerciseLeftControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <UpdateExerciseNameController />
      <UpdateExerciseBurnedKcalsController />
      <UpdateExerciseDurationMinsController />
      <UpdateExerciseDescriptionController />
    </WorkoutsFormFormSubContainer>
  );
};
