import { useFormContext } from "react-hook-form";
import { useToastService } from "../../../../../../../../../context/toast-service-context";
import { useAddUploadedFile } from "../../../../../../../../../hooks/uploaded-file-hooks/use-add-uploaded-file";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../../styles/classes/reusable-classes";
import { CreateLessonInputs } from "../../../../../create-lesson";
import { useCallback, useEffect } from "react";
import { CreateUploadedFileDTO } from "../../../../../../../../../models/uploaded-file-dtos/create-uploaded-file-dto";
import { useCardPartsLoading } from "../../../../../../context/card-parts-loading-context";
import { ImageUpload } from "../../../../../../../../../components/image-upload/image-upload";
import { useImageUploadHelpers } from "../../../../../../../../../components/image-upload/context/image-upload-helpers-context";

type CreateLessonCardPartsImageUploadProps = {
  cardIndex: number;
  cardPartIndex: number;
};

export const CreateLessonCardPartsImageUpload = (
  props: CreateLessonCardPartsImageUploadProps
) => {
  const { cardIndex, cardPartIndex } = props;

  const { showToast } = useToastService();
  const addUploadedFile = useAddUploadedFile();
  const { setCardPartsLoading } = useCardPartsLoading();

  const {
    imageFile,
    setLoading,
    handleUploadedImageState,
    imageUploadState,
    imageFileInputRef,
  } = useImageUploadHelpers();

  const { setValue } = useFormContext<CreateLessonInputs>();

  const onUpload = useCallback(async () => {
    try {
      if (imageFile) {
        const createUploadedFileDTO: CreateUploadedFileDTO = {
          feature: "ContentModuleCardPartImage",
        };

        setLoading(true);
        setCardPartsLoading({
          cardIndex,
          loading: true,
        });
        await addUploadedFile(createUploadedFileDTO, imageFile).then(
          (response) => {
            setValue(
              `contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}.imageUploadedFileURL`,
              response.presignedUrl
            );
            setValue(
              `contentModuleCards.${cardIndex}.contentModuleCardParts.${cardPartIndex}.imageUploadedFileId`,
              response.id
            );
          }
        );
        handleUploadedImageState(imageFile);
        showToast("Success", "Card Image Uploaded Successfully");
        setLoading(false);
        setCardPartsLoading({
          cardIndex: undefined,
          loading: false,
        });
      }
    } catch (error) {
      showToast("Error", "Failed to Upload Card Image :(");
    }
  }, [
    addUploadedFile,
    cardIndex,
    cardPartIndex,
    handleUploadedImageState,
    imageFile,
    setLoading,
    setValue,
    showToast,
    setCardPartsLoading,
  ]);

  useEffect(() => {
    if (imageFile && imageUploadState.state === "Upload") {
      onUpload();
    }
  }, [imageFile, onUpload, imageUploadState]);

  const openUploaderOnMount = useCallback(() => {
    imageFileInputRef.current?.click();
  }, [imageFileInputRef]);

  useEffect(() => {
    openUploaderOnMount();
  }, [openUploaderOnMount]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="50px" $isOptional>
        Image
      </UXInputLabel>
      <ImageUpload uploadText="Image" />
    </UXInputContainer>
  );
};
