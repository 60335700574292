import { ImageUploadHelpersProvider } from "../../../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { UpdateLevelProgramCoverPhotoContainer } from "./update-level-cover-photo-container";

export const UpdateLevelCoverPhotoController = () => {
  return (
    <ImageUploadHelpersProvider>
      <UpdateLevelProgramCoverPhotoContainer />
    </ImageUploadHelpersProvider>
  );
};
