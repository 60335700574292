import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { BASE_PATH } from "../../constants/paths";
import { WorkoutProgramDTO } from "../../models/workout-programs-dtos/workout-program-dtos/workout-program-dto";
import { PutWorkoutProgramDTO } from "../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-program-dto";

export function usePutWorkoutProgram(): (
  programID: number,
  putProgramDTO: PutWorkoutProgramDTO
) => Promise<WorkoutProgramDTO> {
  const client = useClient();

  return useCallback(
    async (programID, putProgramDTO) => {
      const response = await client.put<WorkoutProgramDTO>(
        `${BASE_PATH}/workoutPrograms/${programID}`,
        putProgramDTO
      );

      return response.data;
    },
    [client]
  );
}
