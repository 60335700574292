import { createContext, PropsWithChildren, useContext } from "react";
import { UseLoadableDataReturn } from "../../../../../hooks/use-loadable-data";
import { WorkoutProgramDTO } from "../../../../../models/workout-programs-dtos/workout-program-dtos/workout-program-dto";

export type WorkoutProgram = {
  workoutProgramDTO: WorkoutProgramDTO;
};

export type LoadableWorkoutProgram = UseLoadableDataReturn<WorkoutProgram>;

const LoadableWorkoutProgramContext = createContext<
  LoadableWorkoutProgram | undefined
>(undefined);

export type LoadableWorkoutProgramProviderProps = PropsWithChildren<{
  loadableWorkoutProgram: LoadableWorkoutProgram;
}>;

export function LoadableWorkoutProgramProvider(
  props: LoadableWorkoutProgramProviderProps
) {
  const { loadableWorkoutProgram, children } = props;

  return (
    <LoadableWorkoutProgramContext.Provider value={loadableWorkoutProgram}>
      {children}
    </LoadableWorkoutProgramContext.Provider>
  );
}

export function useLoadableWorkoutProgram(): LoadableWorkoutProgram {
  const loadableWorkoutProgram = useContext(LoadableWorkoutProgramContext);

  if (loadableWorkoutProgram === undefined) {
    throw Error(
      "useLoadableWorkoutProgram must be used within an LoadableWorkoutProgramProvider"
    );
  }

  return loadableWorkoutProgram;
}

export function useWorkoutProgram(): WorkoutProgram {
  const loadableWorkoutProgram = useLoadableWorkoutProgram();

  if (loadableWorkoutProgram.state.data === undefined) {
    throw Error("WorkoutProgram have never been loaded successfully yet.");
  }

  return loadableWorkoutProgram.state.data;
}

export function useReloadWorkoutProgram(): () => void {
  const loadableWorkoutProgram = useLoadableWorkoutProgram();

  return loadableWorkoutProgram.reload;
}
