import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { BASE_PATH } from "../../constants/paths";
import { WorkoutProgramDTO } from "../../models/workout-programs-dtos/workout-program-dtos/workout-program-dto";

export function useGetAllWorkoutPrograms(): () => Promise<WorkoutProgramDTO[]> {
  const client = useClient();

  return useCallback(async () => {
    const response = await client.get<WorkoutProgramDTO[]>(
      `${BASE_PATH}/workoutPrograms`
    );

    return response.data;
  }, [client]);
}
