import { ExerciseDTO } from "../../../../../../../../../models/exercises-dtos/exercise-dto";
import { truncateText } from "../../../../../../../../../utils/truncate-text";
import {
  WorkoutsContainerDayExerciseListItemImageContainer,
  WorkoutsContainerDayExerciseListItemInfoContainer,
  WorkoutsContainerDayExerciseListItemInfoItem,
  WorkoutsContainerDayExerciseListItemInfoSubContainer,
  WorkoutsContainerDayExerciseListItemLGImageContainer,
  WorkoutsContainerDayExerciseListItemListItem,
  WorkoutsContainerDayExerciseListItemNumberContainer,
  WorkoutsContainerDayExerciseListItemTextContainer,
  WorkoutsContainerDayWorkoutList,
} from "./styled-workouts-container-day-workout-exercises";

type WorkoutsContainerDayWorkoutExercisesProps = {
  currentDayExercises: ExerciseDTO[];
};

export const WorkoutsContainerDayWorkoutExercises = (
  props: WorkoutsContainerDayWorkoutExercisesProps
) => {
  const { currentDayExercises } = props;

  return (
    <WorkoutsContainerDayWorkoutList>
      {currentDayExercises.map((exerciseDTO, index) => (
        <WorkoutsContainerDayExerciseListItemListItem key={exerciseDTO.id}>
          <WorkoutsContainerDayExerciseListItemNumberContainer>
            <p>{index + 1}</p>
          </WorkoutsContainerDayExerciseListItemNumberContainer>
          <WorkoutsContainerDayExerciseListItemImageContainer
            $width="50px"
            $height="0px"
          >
            {exerciseDTO.thumbnailUploadedFile ? (
              <>
                <img
                  src={exerciseDTO.thumbnailUploadedFile.presignedUrl}
                  alt={exerciseDTO.name}
                />
                <WorkoutsContainerDayExerciseListItemLGImageContainer
                  src={exerciseDTO.thumbnailUploadedFile.presignedUrl}
                  alt={exerciseDTO.name}
                />
              </>
            ) : (
              <span className="material-symbols-outlined">directions_run</span>
            )}
          </WorkoutsContainerDayExerciseListItemImageContainer>
          <WorkoutsContainerDayExerciseListItemTextContainer>
            <p>{exerciseDTO.name}</p>
            <span title={exerciseDTO.description}>
              {truncateText(exerciseDTO.description, 20)}
            </span>
          </WorkoutsContainerDayExerciseListItemTextContainer>
          <WorkoutsContainerDayExerciseListItemInfoContainer>
            <WorkoutsContainerDayExerciseListItemInfoSubContainer>
              <WorkoutsContainerDayExerciseListItemInfoItem>
                <span className="material-symbols-outlined">schedule</span>
                <p>{exerciseDTO.durationInMins} mins</p>
              </WorkoutsContainerDayExerciseListItemInfoItem>
              <WorkoutsContainerDayExerciseListItemInfoItem>
                <span className="material-symbols-outlined">
                  local_fire_department
                </span>
                <p>{exerciseDTO.burnedKCals} kcal</p>
              </WorkoutsContainerDayExerciseListItemInfoItem>
            </WorkoutsContainerDayExerciseListItemInfoSubContainer>
          </WorkoutsContainerDayExerciseListItemInfoContainer>
        </WorkoutsContainerDayExerciseListItemListItem>
      ))}
    </WorkoutsContainerDayWorkoutList>
  );
};
