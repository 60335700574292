import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../styles/classes/reusable-classes";
import { useExercise } from "../../../context/loadable-exercise-context";

export const ReadExerciseNameController = () => {
  const { exerciseDTO } = useExercise();

  return (
    <UXInputContainer>
      <UXInputLabel $width="150px">Exercise Name</UXInputLabel>
      <UXInputWrapper $width="80%" $readOnly>
        <UXInputInput
          readOnly
          dir="auto"
          type="text"
          defaultValue={exerciseDTO.name}
        />
      </UXInputWrapper>
    </UXInputContainer>
  );
};
