import { useEffect } from "react";
import { Loader } from "../loaders/otida-loader/styled-otida-loader";
import { Button } from "../../styles/classes/reusable-classes";
import { useImageVideoUploadHelpers } from "./context/image-video-upload-helpers-context";
import {
  ImageUploadClearIcon,
  ImageUploadContainer,
  ImageUploadImageContainer,
} from "../image-upload/styled-image-upload";
import {
  VideoUploadContainer,
  VideoUploadVideo,
  VideoUploadVideoContainer,
} from "../../screens/content/components/video-upload/styled-video-upload";

type ImageVideoUploadProps = {
  uploadText?: string;
  readOnly?: boolean;
};

export const ImageVideoUpload = (props: ImageVideoUploadProps) => {
  const { uploadText, readOnly } = props;

  const {
    imageVideoFile,
    imageVideoUploadState,
    handleDrag,
    handleDrop,
    handleFileChange,
    handleRemoveMedia,
    loading,
    setImageVideoFile,
    setImageVideoUploadState,
    imageVideoFileInputRef,
  } = useImageVideoUploadHelpers();

  const isUploading =
    imageVideoFile && imageVideoUploadState.state === "Upload";

  const clearIcon = !readOnly ? (
    <ImageUploadClearIcon
      className="material-symbols-outlined"
      onClick={handleRemoveMedia}
    >
      close
    </ImageUploadClearIcon>
  ) : (
    <></>
  );

  useEffect(() => {
    if (
      imageVideoUploadState.state === "Upload" &&
      imageVideoUploadState.previewUrl === undefined
    ) {
      setImageVideoUploadState({ state: "Idle" });
      setImageVideoFile(null);
    }
  }, [imageVideoUploadState, setImageVideoFile, setImageVideoUploadState]);

  if (loading) {
    return (
      <ImageUploadContainer $dragActive={false}>
        <ImageUploadImageContainer>
          <Loader radius={80} />
        </ImageUploadImageContainer>
      </ImageUploadContainer>
    );
  }

  if (imageVideoUploadState.state === "Uploaded") {
    return (
      <VideoUploadContainer $dragActive={false} $uploadedDimentions>
        {imageVideoFile?.type.startsWith("video") ? (
          <VideoUploadVideoContainer>
            <VideoUploadVideo controls>
              <source
                src={imageVideoUploadState.previewUrl}
                type={imageVideoFile.type}
              />
              Your browser does not support the video tag.
            </VideoUploadVideo>
            {clearIcon}
          </VideoUploadVideoContainer>
        ) : (
          <ImageUploadImageContainer>
            <img
              src={imageVideoUploadState.previewUrl}
              alt={`Course ${uploadText ? uploadText : "Thumbnail"}`}
            />
            {clearIcon}
          </ImageUploadImageContainer>
        )}
      </VideoUploadContainer>
    );
  }

  if (isUploading) {
    return (
      <ImageUploadContainer
        onDragEnter={(event) => handleDrag(event)}
        onDragLeave={(event) => handleDrag(event)}
        onDragOver={(event) => handleDrag(event)}
        onDrop={(event) => handleDrop(event)}
        $dragActive={false}
      >
        {imageVideoFile?.type.startsWith("video") ? (
          <VideoUploadVideoContainer>
            <video controls>
              <source
                src={imageVideoUploadState.previewUrl}
                type={imageVideoFile.type}
              />
              Your browser does not support the video tag.
            </video>
            {clearIcon}
          </VideoUploadVideoContainer>
        ) : (
          <ImageUploadImageContainer>
            <img
              src={imageVideoUploadState.previewUrl}
              alt={`Course ${uploadText ? uploadText : "Thumbnail"}`}
            />
            {clearIcon}
          </ImageUploadImageContainer>
        )}
      </ImageUploadContainer>
    );
  }

  if (
    (imageVideoUploadState.state === "Drag" ||
      imageVideoUploadState.state === "Idle") &&
    !readOnly
  ) {
    return (
      <ImageUploadContainer
        onDragEnter={(event) => handleDrag(event)}
        onDragLeave={(event) => handleDrag(event)}
        onDragOver={(event) => handleDrag(event)}
        onDrop={(event) => handleDrop(event)}
        $dragActive={
          imageVideoUploadState.state === "Drag" &&
          imageVideoUploadState.dragActive
        }
        onClick={() => imageVideoFileInputRef.current?.click()}
      >
        <span className="material-symbols-outlined">image</span>
        <p>
          Drag and drop a {uploadText ? uploadText : `file (image or video)`}
        </p>
        <input
          ref={imageVideoFileInputRef}
          type="file"
          accept="image/jpeg, image/png, image/jpg, image/bmp, image/webp, video/mp4, video/webm"
          onChange={(event) => handleFileChange(event)}
        />
        <Button outlineNoBorder type="button">
          Upload {uploadText ? uploadText : `file`}
        </Button>
      </ImageUploadContainer>
    );
  }

  return (
    <ImageUploadContainer $dragActive={false}>
      <span className="material-symbols-outlined">image</span>
    </ImageUploadContainer>
  );
};
