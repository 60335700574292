import { FormProvider, useForm } from "react-hook-form";
import { ContentScreenContainer } from "../../styles/content-reusable-classes";
import { CreateModuleHeader } from "./create-module-header";
import { CreateModuleControllers } from "./create-module-controllers/create-module-controllers";
import { useToastService } from "../../../../context/toast-service-context";
import { useAddContentModule } from "../../../../hooks/content-module-hooks/use-add-content-module";
import { useReloadContent } from "../../context/loadable-content-context";
import { useNavigate, useParams } from "react-router-dom";
import { CreateContentModuleDTO } from "../../../../models/content-module-dtos/create-content-module-dto";
import { TargetAudienceDTO } from "../../../../models/target-audience-dtos/target-audience-dto";
import { ImageUploadHelpersProvider } from "../../../../components/image-upload/context/image-upload-helpers-context";

export type CreateModuleInputs = {
  name?: string;
  description?: string;
  objective?: string;
  status: boolean;
  order?: number;
  thumbnailUploadedFileId?: number;
  targetAudience: TargetAudienceDTO[];
};

export const CreateModule = () => {
  const { courseID } = useParams();
  const parentContentModuleId = parseInt(courseID!);

  const createModuleFormMethods = useForm<CreateModuleInputs>();

  const { showToast } = useToastService();
  const addContentModule = useAddContentModule();
  const reloadContent = useReloadContent();
  const navigate = useNavigate();

  const onSubmit = createModuleFormMethods.handleSubmit(async (inputs) => {
    try {
      const targetAudience = inputs.targetAudience;
      const createContentModuleDTO: CreateContentModuleDTO = {
        parentContentModuleId: parentContentModuleId,
        contentModuleType: "Module",
        uiOrder: inputs.order,
        name: inputs.name!,
        isDraft: inputs.status === true ? false : true,
        description: inputs.description,
        objective: inputs.objective,
        thumbnailUploadedFileId: inputs.thumbnailUploadedFileId,
        targetAudienceIds:
          targetAudience.length > 0
            ? targetAudience.map((audience) => audience.id)
            : [],
        childContentModuleIds: [],
        contentModuleCards: [],
      };
      await addContentModule(createContentModuleDTO).then((response) =>
        navigate(
          `../courses/course/${parentContentModuleId}/module/${response.id}`
        )
      );
      showToast("Success", "Module Added Successfully!");
      reloadContent();
    } catch (error) {
      showToast("Error", "Faild to Add Module :(");
    }
  });

  return (
    <FormProvider {...createModuleFormMethods}>
      <ContentScreenContainer $lessonUI={false}>
        <form onSubmit={onSubmit}>
          <ImageUploadHelpersProvider>
            <CreateModuleHeader />
            <CreateModuleControllers />
          </ImageUploadHelpersProvider>
        </form>
      </ContentScreenContainer>
    </FormProvider>
  );
};
