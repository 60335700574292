import { useParams } from "react-router-dom";
import { AddLevelForm } from "./add-level-form";
import { useGetOneWorkoutProgram } from "../../../../../../hooks/workout-program-hooks/use-get-one-workout-program";
import { useCallback } from "react";
import { Loading } from "../../../../../../components/loading";

export const AddLevel = () => {
  const { programID } = useParams();

  const getOneWorkoutProgram = useGetOneWorkoutProgram();

  const loadOneWorkoutProgram = useCallback(async () => {
    const id = parseInt(programID!);

    const [workoutProgramDTO] = await Promise.all([getOneWorkoutProgram(id)]);

    return { workoutProgramDTO };
  }, [getOneWorkoutProgram, programID]);

  return (
    <Loading
      load={loadOneWorkoutProgram}
      successComponent={(data) => (
        <AddLevelForm workoutProgramDTO={data.workoutProgramDTO} />
      )}
    />
  );
};
