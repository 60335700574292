import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { BASE_PATH } from "../../constants/paths";
import { ExerciseDTO } from "../../models/exercises-dtos/exercise-dto";

export function useGetOneExercise(): (
  exerciseID: number
) => Promise<ExerciseDTO> {
  const client = useClient();

  return useCallback(
    async (exerciseID) => {
      const response = await client.get<ExerciseDTO>(
        `${BASE_PATH}/exercises/${exerciseID}`
      );

      return response.data;
    },
    [client]
  );
}
