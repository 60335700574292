import { Button } from "../../../../../../../styles/classes/reusable-classes";
import {
  WorkoutsContainerList,
  WorkoutsContainerListItem,
  WorkoutsContainerWrapper,
  WorkoutsContainerTitle,
  WorkoutsContainerHeader,
} from "./styled-workouts-container";
import { WorkoutsExercisesModal } from "./workouts-exercises-modal/workouts-exercises-modal";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AddLevelInputs } from "../../add-level/types/add-level-inputs";
import { WorkoutsContainerDayWorkout } from "./workouts-container-day-workout/workouts-container-day-workout";
import { useCallback, useRef } from "react";
import { WorkoutsContainerRestDayCard } from "./workouts-container-day-workout/workouts-container-rest-day-card";
import { useDropdown } from "../../../../../../../components/dropdown/hooks/use-dropdown";

export const WorkoutsContainer = () => {
  const { watch, setValue, control, getValues } = useFormContext<
    AddLevelInputs
  >();
  const workoutsWatcher = watch("workouts");

  const { remove, move, append } = useFieldArray<AddLevelInputs>({
    control,
    name: "workouts",
  });

  const dragItem = useRef<number>(0);
  const draggedOverItem = useRef<number>(0);

  const handleSort = useCallback(() => {
    move(dragItem.current, draggedOverItem.current);

    // After moving, update the `uiOrder` field for all items
    setValue(
      "workouts",
      getValues("workouts")?.map((item, index) => ({
        ...item,
        dayNumber: index + 1, // Assuming `uiOrder` starts at 1
      }))
    );
  }, [move, getValues, setValue]);

  const listItems = [1, 2, 3, 4, 5, 6, 7].map((day, index) => {
    if (
      workoutsWatcher &&
      workoutsWatcher.some((item) => item.dayNumber === day)
    ) {
      if (
        workoutsWatcher.some(
          (item) =>
            item.dayNumber === day &&
            item.name === "Rest Day" &&
            item.description === "This day is For Rest"
        )
      ) {
        return (
          <WorkoutsContainerRestDayCard
            key={`RestDay ${day} Workout`}
            dayNumber={day}
            remove={remove}
            handleSort={handleSort}
            dragItem={dragItem}
            draggedOverItem={draggedOverItem}
            index={index}
          />
        );
      } else {
        return (
          <WorkoutsContainerDayWorkout
            key={`Day ${day} Workout`}
            dayNumber={day}
            remove={remove}
            handleSort={handleSort}
            dragItem={dragItem}
            draggedOverItem={draggedOverItem}
            index={index}
          />
        );
      }
    } else {
      return (
        <WorkoutsContainerListItem key={day}>
          <p>Day {day}</p>
          <WorkoutsExercisesModal
            day={day}
            setWorkoutsValue={setValue}
            workoutsWatcher={workoutsWatcher}
          />
          <Button
            type="button"
            outlined
            onClick={() =>
              append({
                dayNumber: day,
                name: "Rest Day",
                description: "This day is For Rest",
              })
            }
          >
            Rest Day
          </Button>
        </WorkoutsContainerListItem>
      );
    }
  });

  const dropdownDefaultValue = true;
  const disableDropdownOnElseWhereClick = true;

  const { dropdownContainerRef, showDropdown, toggleDropdown } = useDropdown(
    dropdownDefaultValue,
    disableDropdownOnElseWhereClick
  );

  return (
    <WorkoutsContainerWrapper ref={dropdownContainerRef}>
      <WorkoutsContainerHeader $showDropdown={showDropdown}>
        <WorkoutsContainerTitle>Days 1 to 7</WorkoutsContainerTitle>
        <span className="material-symbols-outlined" onClick={toggleDropdown}>
          keyboard_arrow_down
        </span>
      </WorkoutsContainerHeader>
      <WorkoutsContainerList $showDropdown={showDropdown}>
        {listItems}
      </WorkoutsContainerList>
    </WorkoutsContainerWrapper>
  );
};
