import { FormProvider, useForm } from "react-hook-form";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
  WorkoutsFormFormSubContainer,
  WorkoutsScreenHeader,
} from "../../../../styles/workouts-common-styles";
import { AddLevelInputs } from "./types/add-level-inputs";
import { AddLevelLeftControllers } from "./controllers/add-level-left-controllers/add-level-left-controllers";
import { LevelCoverPhotoController } from "./controllers/add-level-right-controllers/add-level-cover-photo-controller";
import { usePutWorkoutProgram } from "../../../../../../hooks/workout-program-hooks/use-put-workout-program";
import { useToastService } from "../../../../../../context/toast-service-context";
import { WorkoutProgramDTO } from "../../../../../../models/workout-programs-dtos/workout-program-dtos/workout-program-dto";
import { PutWorkoutProgramDTO } from "../../../../../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-program-dto";
import { PutWorkoutProgramLevelDTO } from "../../../../../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-program-level-dto";
import { useNavigate } from "react-router-dom";
import { Workouts } from "../workouts/workouts";

type AddLevelFormProps = {
  workoutProgramDTO: WorkoutProgramDTO;
};

export const AddLevelForm = (props: AddLevelFormProps) => {
  const { workoutProgramDTO } = props;

  const { showToast } = useToastService();
  const addLevelFormMethods = useForm<AddLevelInputs>();

  const putWorkoutProgram = usePutWorkoutProgram();

  const navigate = useNavigate();

  const onSubmit = addLevelFormMethods.handleSubmit(async (inputs) => {
    try {
      const putWorkoutProgramLevelDTO: PutWorkoutProgramLevelDTO = {
        name: inputs.name,
        coverPhotoUploadedFileId: inputs.coverPhotoUploadedFileId,
        daysCount: Number(inputs.daysCount),
        uiOrder: Number(inputs.uiOrder),
        workouts: inputs.workouts,
      };

      const restOfWorkoutProgramLevels: PutWorkoutProgramLevelDTO[] = workoutProgramDTO.workoutProgramLevels
        ? [
            ...workoutProgramDTO.workoutProgramLevels.map((item) => ({
              id: item.id,
              name: item.name,
              coverPhotoUploadedFileId: item.coverPhotoUploadedFileId,
              daysCount: item.daysCount,
              uiOrder: item.uiOrder,
            })),
          ]
        : [];

      const putWorkoutProgramDTO: PutWorkoutProgramDTO = {
        ...workoutProgramDTO,
        workoutProgramLevels: [
          ...restOfWorkoutProgramLevels,
          putWorkoutProgramLevelDTO,
        ],
      };

      await putWorkoutProgram(workoutProgramDTO.id, putWorkoutProgramDTO).then(
        (res) => {
          if (res.workoutProgramLevels) {
            const newLevelID = res.workoutProgramLevels?.sort(
              (a, b) => a.uiOrder - b.uiOrder
            )[res.workoutProgramLevels.length - 1].id;

            navigate(
              `/workouts/program/${workoutProgramDTO.id}/level/${newLevelID}`
            );
          }
        }
      );
      showToast("Success", "Success Adding Level");
    } catch (error) {
      showToast("Error", "Faild to Add Level :(");
    }
  });

  return (
    <FormProvider {...addLevelFormMethods}>
      <form onSubmit={onSubmit}>
        <WorkoutsScreenHeader>
          <p>Level</p>
          <Button type="submit">Save</Button>
        </WorkoutsScreenHeader>
        <WorkoutsFormContainer>
          <WorkoutsFormControllersContainer>
            <AddLevelLeftControllers />
            <WorkoutsFormFormSubContainer>
              <LevelCoverPhotoController />
            </WorkoutsFormFormSubContainer>
          </WorkoutsFormControllersContainer>
          <Workouts />
        </WorkoutsFormContainer>
      </form>
    </FormProvider>
  );
};
