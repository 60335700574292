import { Controller, useFormContext } from "react-hook-form";
import { AddLevelInputs } from "../../types/add-level-inputs";
import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../../styles/classes/reusable-classes";

export const AddLevelNameController = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AddLevelInputs>();

  return (
    <Controller
      control={control}
      name="name"
      rules={{ required: true }}
      render={({ field: { value, onChange } }) => (
        <UXInputContainer>
          <UXInputLabel $width="150px">Level Name</UXInputLabel>
          <UXInputWrapper
            $errorState={errors.name?.type === "required"}
            $width="80%"
          >
            <UXInputInput
              width={"100%"}
              dir="auto"
              type="text"
              placeholder="Enter Level Name"
              value={value === undefined ? "" : value}
              onChange={(e) =>
                onChange(e.target.value === "" ? undefined : e.target.value)
              }
            />
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
