import { useMemo } from "react";
import { useContent } from "../../context/loadable-content-context";
import { Link, useParams } from "react-router-dom";
import { Button } from "../../../../styles/classes/reusable-classes";
import { Loader } from "../../../../components/loaders/otida-loader/styled-otida-loader";
import { useFormContext } from "react-hook-form";
import { CreateLessonInputs } from "./create-lesson";
import { useCardPartsLoading } from "../context/card-parts-loading-context";
import {
  ContentModulesFormHeaderContainer,
  ContentModulesFormHeaderButtonContainer,
  ContentModulesFormHeaderTitle,
} from "../../styles/content-modules-form-controllers/content-modules-form-controllers-css";
import { useImageUploadHelpers } from "../../../../components/image-upload/context/image-upload-helpers-context";

export const CreateLessonHeader = () => {
  const { courseID, moduleID } = useParams();
  const contentCourseID = parseInt(courseID!);
  const contentCourseModuleID = parseInt(moduleID!);

  const { contentModuleDTOs } = useContent();
  const { loading } = useImageUploadHelpers();
  const { cardPartsLoading } = useCardPartsLoading();

  const { watch } = useFormContext<CreateLessonInputs>();
  const lessonNameWatcher = watch("name");
  const lessonUiOrderWatcher = watch("order");

  const { currentContentCourse, currentContentCourseModule } = useMemo(() => {
    const currentContentCourse = contentModuleDTOs.find(
      (contentCourse) => contentCourse.id === contentCourseID
    );

    const currentContentCourseModule = currentContentCourse?.childContentModules?.find(
      (contentCourseModule) => contentCourseModule.id === contentCourseModuleID
    );

    return { currentContentCourse, currentContentCourseModule };
  }, [contentCourseID, contentModuleDTOs, contentCourseModuleID]);

  return (
    <ContentModulesFormHeaderContainer>
      <ContentModulesFormHeaderTitle>
        <p dir="auto">
          <span>Course</span>
          <Link to={`/content/courses/course/${contentCourseID}`} dir="auto">
            {currentContentCourse?.name}
          </Link>
        </p>
        {currentContentCourseModule !== undefined && (
          <>
            <span className="material-symbols-outlined">chevron_right</span>
            <p dir="auto">
              <span>
                Module{" "}
                {currentContentCourseModule.uiOrder !== undefined
                  ? currentContentCourseModule.uiOrder
                  : ""}
              </span>
              <Link
                to={`/content/courses/course/${contentCourseID}/module/${contentCourseModuleID}`}
                dir="auto"
              >
                {currentContentCourseModule.name}
              </Link>
            </p>
          </>
        )}
        {lessonNameWatcher !== undefined && lessonNameWatcher !== "" && (
          <>
            <span className="material-symbols-outlined">chevron_right</span>
            <p dir="auto">
              <span dir="auto">
                Lesson{" "}
                {lessonUiOrderWatcher !== undefined ? lessonUiOrderWatcher : ""}
              </span>
              {lessonNameWatcher}
            </p>
          </>
        )}
      </ContentModulesFormHeaderTitle>
      <ContentModulesFormHeaderButtonContainer>
        <Button type="submit" disabled={loading || cardPartsLoading.loading}>
          {loading || cardPartsLoading.loading ? (
            <Loader radius={20} />
          ) : (
            `Save`
          )}
        </Button>
      </ContentModulesFormHeaderButtonContainer>
    </ContentModulesFormHeaderContainer>
  );
};
