import { WorkoutsFormFormSubContainer } from "../../../../../styles/workouts-common-styles";
import { ReadProgramDefaultController } from "./read-program-default-controller";
import { ReadProgramDraftController } from "./read-program-draft-controller";
import { ReadProgramNameController } from "./read-program-name-controller";

export const ReadProgramLeftControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <ReadProgramNameController />
      <ReadProgramDraftController />
      <ReadProgramDefaultController />
    </WorkoutsFormFormSubContainer>
  );
};
