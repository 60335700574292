import { useParams } from "react-router-dom";
import { Button } from "../../../../../../../styles/classes/reusable-classes";
import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
  WorkoutsFormFormSubContainer,
  WorkoutsScreenHeader,
} from "../../../../../styles/workouts-common-styles";
import { useWorkoutProgram } from "../../../context/loadable-workout-program-context";
import { useLevelState } from "../context/level-state-context";
import { ReadLevelLeftControllers } from "./controllers/read-level-left-controllers/read-level-left-controllers";
import { ReadLevelCoverPhotoController } from "./controllers/read-level-right-controllers/read-level-cover-photo-controller";

export const ReadLevelForm = () => {
  const { setLevelState } = useLevelState();

  const { workoutProgramDTO } = useWorkoutProgram();

  const { levelID } = useParams();

  const currentLevel = workoutProgramDTO.workoutProgramLevels?.find(
    (item) => item.id === parseInt(levelID!)
  );

  return (
    <>
      <WorkoutsScreenHeader>
        <p>Level</p>
        <Button type="button" onClick={() => setLevelState("Update")}>
          Edit
        </Button>
      </WorkoutsScreenHeader>
      <WorkoutsFormContainer>
        <WorkoutsFormControllersContainer>
          <ReadLevelLeftControllers currentLevel={currentLevel} />
          <WorkoutsFormFormSubContainer>
            <ReadLevelCoverPhotoController />
          </WorkoutsFormFormSubContainer>
        </WorkoutsFormControllersContainer>
      </WorkoutsFormContainer>
    </>
  );
};
