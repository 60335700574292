import { ImageUploadHelpersProvider } from "../../../../../../components/image-upload/context/image-upload-helpers-context";
import { AddProgramCoverPhotoContainer } from "./add-program-cover-photo-container";

export const ProgramCoverPhotoController = () => {
  return (
    <ImageUploadHelpersProvider>
      <AddProgramCoverPhotoContainer />
    </ImageUploadHelpersProvider>
  );
};
