import { useFormContext } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { useAddUploadedFile } from "../../../../../../../../../hooks/uploaded-file-hooks/use-add-uploaded-file";
import { useToastService } from "../../../../../../../../../context/toast-service-context";
import { useImageUploadHelpers } from "../../../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { UpdateLevelInputs } from "../../types/update-level-inputs";
import { CreateUploadedFileDTO } from "../../../../../../../../../models/uploaded-file-dtos/create-uploaded-file-dto";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../../styles/classes/reusable-classes";
import { ImageUpload } from "../../../../../../../../../components/image-upload/image-upload";
import { useWorkoutProgram } from "../../../../../context/loadable-workout-program-context";
import { useParams } from "react-router-dom";

export const UpdateLevelProgramCoverPhotoContainer = () => {
  const { workoutProgramDTO } = useWorkoutProgram();

  const { levelID } = useParams();

  const currentLevel = workoutProgramDTO.workoutProgramLevels?.find(
    (item) => item.id === parseInt(levelID!)
  );
  const coverPhotoUploadedFile = currentLevel?.coverPhotoUploadedFile;

  const {
    imageFile,
    imageUploadState,
    setImageFile,
    setImageUploadState,
    handleUploadedImageState,
    setLoading,
  } = useImageUploadHelpers();

  const addUploadedFile = useAddUploadedFile();
  const { showToast } = useToastService();
  const { setValue } = useFormContext<UpdateLevelInputs>();

  useEffect(() => {
    if (coverPhotoUploadedFile) {
      setImageUploadState({
        state: "Uploaded",
        previewUrl: coverPhotoUploadedFile.presignedUrl,
      });

      const jsonCoverPhotoUploadedFile = JSON.stringify(coverPhotoUploadedFile);
      const blob = new Blob([jsonCoverPhotoUploadedFile], {
        type: "application/json",
      });
      setImageFile(blob);
    }
  }, [setImageUploadState, coverPhotoUploadedFile, setImageFile]);

  const onUpload = useCallback(async () => {
    try {
      if (imageFile) {
        const createUploadedFileDTO: CreateUploadedFileDTO = {
          feature: "ContentModuleThumbnail",
        };

        setLoading(true);
        await addUploadedFile(
          createUploadedFileDTO,
          imageFile
        ).then((response) => setValue("coverPhotoUploadedFileId", response.id));
        handleUploadedImageState(imageFile);
        showToast("Success", "Cover Photo Uploaded Successfully");
        setLoading(false);
      }
    } catch (error) {
      showToast("Error", "Failed to Upload Cover Photo :(");
    }
  }, [
    imageFile,
    setLoading,
    showToast,
    addUploadedFile,
    handleUploadedImageState,
    setValue,
  ]);

  useEffect(() => {
    if (imageFile && imageUploadState.state === "Upload") {
      onUpload();
    } else if (
      imageUploadState.state === "Upload" &&
      imageUploadState.previewUrl === undefined
    ) {
      setValue("coverPhotoUploadedFileId", undefined);
    }
  }, [imageFile, onUpload, imageUploadState, setValue]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px" $isOptional>
        Level CoverPhoto
        <span>(Optional)</span>
      </UXInputLabel>
      <ImageUpload uploadText="Cover Photo" />
    </UXInputContainer>
  );
};
