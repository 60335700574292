import { useEffect } from "react";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../styles/classes/reusable-classes";
import { useVideoUploadHelpers } from "../../../../../../../content/components/video-upload/context/video-upload-helpers-context";
import { VideoUpload } from "../../../../../../../content/components/video-upload/video-upload";
import { useExercise } from "../../../../context/loadable-exercise-context";

export const ReadExerciseMediaContainer = () => {
  const { exerciseDTO } = useExercise();
  const mediaUploadedFile = exerciseDTO.mediaUploadedFile;

  const { setVideoFile, setVideoUploadState } = useVideoUploadHelpers();

  useEffect(() => {
    if (mediaUploadedFile) {
      setVideoUploadState({
        state: "Uploaded",
        previewUrl: mediaUploadedFile.presignedUrl,
      });

      const jsonThumbnailUploadedFile = JSON.stringify(mediaUploadedFile);
      const blob = new Blob([jsonThumbnailUploadedFile], {
        type: "application/json",
      });
      setVideoFile(blob);
    }
  }, [setVideoUploadState, mediaUploadedFile, setVideoFile]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px">Exercise Media</UXInputLabel>
      <VideoUpload readOnly uploadText="Media" />
    </UXInputContainer>
  );
};
