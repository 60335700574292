import { useLocation } from "react-router-dom";
import { useExerciseState } from "./context/exercise-state-context";
import { ReadExerciseForm } from "./read-exercise-form/read-exercise-form";
import { UpdateExercise } from "./update-exercise-form/update-exercise-form";
import { useEffect } from "react";

export const ExerciseState = () => {
  const { exerciseState, setExerciseState } = useExerciseState();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith("update")) {
      setExerciseState("Update");
    }
  }, [setExerciseState, location]);

  if (exerciseState === "Read") {
    return <ReadExerciseForm />;
  }

  return <UpdateExercise />;
};
