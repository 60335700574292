import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { BASE_PATH } from "../../constants/paths";
import { CreateWorkoutProgramDTO } from "../../models/workout-programs-dtos/create-workout-program-dtos/create-workout-program-dto";
import { WorkoutProgramDTO } from "../../models/workout-programs-dtos/workout-program-dtos/workout-program-dto";

export function useAddWorkoutProgram(): (
  createWorkoutProgramDTO: CreateWorkoutProgramDTO
) => Promise<WorkoutProgramDTO> {
  const client = useClient();

  return useCallback(
    async (createWorkoutProgramDTO) => {
      const response = await client.post<WorkoutProgramDTO>(
        `${BASE_PATH}/workoutPrograms`,
        createWorkoutProgramDTO
      );

      return response.data;
    },
    [client]
  );
}
