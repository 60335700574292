import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../styles/classes/reusable-classes";
import { useMemo, useState } from "react";
import { TablePaginationContainer } from "../../../../components/table/styled-table";
import { Pagination } from "../../../../components/pagination/pagination";
import { Table } from "../../../../components/table/table";
import { TableHeader } from "../../../../components/table/table-header/table-header";
import { useWorkoutPrograms } from "../context/loadable-workout-programs-context";
import { ProgramsTableHeadings } from "./programs-table-headings";
import { ProgramsTableRows } from "./programs-table-rows";
import { EmptyState } from "../../../../components/empty-state/empty-state";
import { WorkoutsFormContainer } from "../../styles/workouts-common-styles";

export const ProgramsTable = () => {
  const { programsPage } = useParams();
  const { workoutProgramDTOs } = useWorkoutPrograms();

  const [searchTerm, setSearchTerm] = useState("");
  const [tableRowsPerPage, setTableRowsPerPage] = useState(8);

  const navigate = useNavigate();

  const sorted = useMemo(
    () => workoutProgramDTOs.sort((a, b) => a.name.localeCompare(b.name)),
    [workoutProgramDTOs]
  );

  const filtered = useMemo(
    () =>
      sorted.filter((item) => {
        if (searchTerm === "") {
          return item;
        } else if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        } else {
          return 0;
        }
      }),
    [sorted, searchTerm]
  );

  const maxPage = Math.ceil(filtered.length / tableRowsPerPage);

  const currentOrMaxPage = Math.min(Number(programsPage), maxPage);

  const currentPagePrograms = useMemo(() => {
    const indexOfLastEntry = currentOrMaxPage * tableRowsPerPage;
    const indexOfFirstEntry = indexOfLastEntry - tableRowsPerPage;

    return filtered.slice(indexOfFirstEntry, indexOfLastEntry);
  }, [filtered, tableRowsPerPage, currentOrMaxPage]);

  function onSearchTermChange(searchTerm: string) {
    navigate(`../programs/1`);
    setSearchTerm(searchTerm);
  }

  if (currentPagePrograms.length === 0) {
    return (
      <EmptyState
        hasHeader={false}
        hasSearch={false}
        text="No programs yet created"
        title=""
        button={
          <Link to={`/workouts/create-new-program`}>
            <Button type="button">Add Program</Button>
          </Link>
        }
      />
    );
  }

  return (
    <WorkoutsFormContainer>
      <TableHeader
        title="All Programs"
        searchTerm={searchTerm}
        setSearchTerm={onSearchTermChange}
        boldTitle
        button={
          <Link to={`/workouts/create-new-program`}>
            <Button type="button">Add Program</Button>
          </Link>
        }
      />
      <Table
        tableHeadings={<ProgramsTableHeadings />}
        tableRows={<ProgramsTableRows programDTOs={currentPagePrograms} />}
      />
      <TablePaginationContainer>
        <Pagination
          totalRowsCount={filtered.length}
          tableRowsPerPage={tableRowsPerPage}
          setRowsPerPage={setTableRowsPerPage}
          navigateBasePath={"/programs"}
          pageVariable={programsPage}
        />
      </TablePaginationContainer>
    </WorkoutsFormContainer>
  );
};
