import { useEffect } from "react";
import { useImageUploadHelpers } from "../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { ImageUpload } from "../../../../../../../components/image-upload/image-upload";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../styles/classes/reusable-classes";
import { useWorkoutProgram } from "../../../context/loadable-workout-program-context";

export const ReadProgramCoverPhotoContainer = () => {
  const { workoutProgramDTO } = useWorkoutProgram();
  const coverPhotoUploadedFile = workoutProgramDTO.coverPhotoUploadedFile;

  const { setImageFile, setImageUploadState } = useImageUploadHelpers();

  useEffect(() => {
    if (coverPhotoUploadedFile) {
      setImageUploadState({
        state: "Uploaded",
        previewUrl: coverPhotoUploadedFile.presignedUrl,
      });

      const jsonCoverPhotoUploadedFile = JSON.stringify(coverPhotoUploadedFile);
      const blob = new Blob([jsonCoverPhotoUploadedFile], {
        type: "application/json",
      });
      setImageFile(blob);
    }
  }, [setImageUploadState, coverPhotoUploadedFile, setImageFile]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px" $isOptional>
        Program CoverPhoto
      </UXInputLabel>
      <ImageUpload readOnly uploadText="Cover Photo" />
    </UXInputContainer>
  );
};
