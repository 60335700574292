import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { BASE_PATH } from "../../constants/paths";

export function useDeleteExercise(): (exerciseID: number) => Promise<void> {
  const client = useClient();

  return useCallback(
    async (exerciseID: number) => {
      await client.delete<void>(`${BASE_PATH}/exercises/${exerciseID}`);
    },
    [client]
  );
}
