import { VideoUploadHelpersProvider } from "../../../../../../../content/components/video-upload/context/video-upload-helpers-context";
import { ReadExerciseMediaContainer } from "./read-exercise-media-container";

export const ReadExerciseMediaController = () => {
  return (
    <VideoUploadHelpersProvider>
      <ReadExerciseMediaContainer />
    </VideoUploadHelpersProvider>
  );
};
