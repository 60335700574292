import { useDropdown } from "../../../../../../../../../../components/dropdown/hooks/use-dropdown";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../../../styles/classes/reusable-classes";
import { useWorkoutExercisesModalAutoSelectSearchTerm } from "./context/workout-exercises-modal-auto-select-search-term-context";
import {
  WrokoutsExercisesModalAutoSelectContainer,
  WrokoutsExercisesModalAutoSelectDropdownToggle,
  WrokoutsExercisesModalAutoSelectDropdownToggleIcon,
  WrokoutsExercisesModalAutoSelectExercisesSearchBox,
  WrokoutsExercisesModalAutoSelectExercisesSearchInput,
} from "./styled-workouts-exercises-modal-auto-select";
import { WorkoutExercisesAutoSelectList } from "./workouts-exercises-modal-auto-select-list/workouts-exercises-modal-auto-select-list";
import { ExerciseDTO } from "../../../../../../../../../../models/exercises-dtos/exercise-dto";

type WorkoutsExercisesModalAutoSelectProps = {
  append: (exerciseDTO: ExerciseDTO, index: number) => void;
};

export const WorkoutsExercisesModalAutoSelect = (
  props: WorkoutsExercisesModalAutoSelectProps
) => {
  const { append } = props;

  const { dropdownContainerRef, showDropdown, toggleDropdown } = useDropdown();

  const {
    searchTerm,
    handleOnSearchInputChange,
  } = useWorkoutExercisesModalAutoSelectSearchTerm();

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel>Exercises</UXInputLabel>
      <WrokoutsExercisesModalAutoSelectContainer ref={dropdownContainerRef}>
        <WrokoutsExercisesModalAutoSelectDropdownToggle
          onClick={toggleDropdown}
        >
          <WrokoutsExercisesModalAutoSelectExercisesSearchBox>
            <span className="material-symbols-outlined">search</span>
            <WrokoutsExercisesModalAutoSelectExercisesSearchInput
              placeholder="Search exercises..."
              type="search"
              value={searchTerm}
              onChange={(event) => handleOnSearchInputChange(event)}
            />
          </WrokoutsExercisesModalAutoSelectExercisesSearchBox>
          <WrokoutsExercisesModalAutoSelectDropdownToggleIcon
            className="material-symbols-outlined"
            $showDropdown={showDropdown}
          >
            expand_more
          </WrokoutsExercisesModalAutoSelectDropdownToggleIcon>
        </WrokoutsExercisesModalAutoSelectDropdownToggle>
        <WorkoutExercisesAutoSelectList
          showDropdown={showDropdown}
          append={append}
        />
      </WrokoutsExercisesModalAutoSelectContainer>
    </UXInputContainer>
  );
};
