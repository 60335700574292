import {
  UXInputContainer,
  UXInputLabel,
  UXInputTextarea,
  UXInputWrapper,
} from "../../../../../../../styles/classes/reusable-classes";
import { useExercise } from "../../../context/loadable-exercise-context";

export const ReadExerciseDescriptionController = () => {
  const { exerciseDTO } = useExercise();

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px">Description</UXInputLabel>
      <UXInputWrapper $textareaDimentions $width="80%" $readOnly>
        <UXInputTextarea
          dir="auto"
          readOnly
          defaultValue={exerciseDTO.description}
        />
      </UXInputWrapper>
    </UXInputContainer>
  );
};
