import { useCallback } from "react";
import { useGetOneExercise } from "../../../../hooks/exercises/use-get-one-exercise";
import { useParams } from "react-router-dom";
import { ExerciseStateProvider } from "./context/exercise-state-context";
import { ExerciseState } from "./exercise-state";
import { LoadableDataLoading } from "../../../../components/loadable-data-loading";
import { LoadableExerciseProvider } from "./context/loadable-exercise-context";
import { useLoadableData } from "../../../../hooks/use-loadable-data";

export const Exercise = () => {
  const { exerciseID } = useParams();
  const id = Number(exerciseID!);
  const getOneExercise = useGetOneExercise();

  const loadOneExerciseCallback = useCallback(async () => {
    const [exerciseDTO] = await Promise.all([getOneExercise(id)]);

    return { exerciseDTO };
  }, [getOneExercise, id]);

  const loadableExersice = useLoadableData(loadOneExerciseCallback);

  return (
    <LoadableDataLoading
      state={loadableExersice.state}
      successComponent={(data) => (
        <LoadableExerciseProvider loadableExercise={loadableExersice}>
          <ExerciseStateProvider>
            <ExerciseState />
          </ExerciseStateProvider>
        </LoadableExerciseProvider>
      )}
    />
  );
};
