import { createContext, PropsWithChildren, useContext } from "react";
import { ExerciseDTO } from "../../../../../../../models/exercises-dtos/exercise-dto";
import { UseLoadableDataReturn } from "../../../../../../../hooks/use-loadable-data";

export type Workouts = {
  exerciseDTOs: ExerciseDTO[];
};

export type LoadableWorkouts = UseLoadableDataReturn<Workouts>;

const LoadableWorkoutsContext = createContext<LoadableWorkouts | undefined>(
  undefined
);

export type LoadableWorkoutsProviderProps = PropsWithChildren<{
  loadableWorkouts: LoadableWorkouts;
}>;

export function LoadableWorkoutsProvider(props: LoadableWorkoutsProviderProps) {
  const { loadableWorkouts, children } = props;

  return (
    <LoadableWorkoutsContext.Provider value={loadableWorkouts}>
      {children}
    </LoadableWorkoutsContext.Provider>
  );
}

export function useLoadableWorkouts(): LoadableWorkouts {
  const loadableWorkouts = useContext(LoadableWorkoutsContext);

  if (loadableWorkouts === undefined) {
    throw Error(
      "useLoadableWorkouts must be used within an LoadableWorkoutsProvider"
    );
  }

  return loadableWorkouts;
}

export function useWorkouts(): Workouts {
  const loadableWorkouts = useLoadableWorkouts();

  if (loadableWorkouts.state.data === undefined) {
    throw Error("Workouts have never been loaded successfully yet.");
  }

  return loadableWorkouts.state.data;
}

export function useReloadWorkouts(): () => void {
  const loadableWorkouts = useLoadableWorkouts();

  return loadableWorkouts.reload;
}
