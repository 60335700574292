import { ImageUploadHelpersProvider } from "../../../../../../../../components/image-upload/context/image-upload-helpers-context";
import { LevelProgramCoverPhotoContainer } from "./add-level-cover-photo-container";

export const LevelCoverPhotoController = () => {
  return (
    <ImageUploadHelpersProvider>
      <LevelProgramCoverPhotoContainer />
    </ImageUploadHelpersProvider>
  );
};
