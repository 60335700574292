import { FormProvider, useForm } from "react-hook-form";
import { Button } from "../../../../styles/classes/reusable-classes";
import { AddExerciseInputs } from "./types/add-exercise-inputs";
import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
  WorkoutsScreenHeader,
} from "../../styles/workouts-common-styles";
import { useAddExercise } from "../../../../hooks/exercises/use-add-exercise";
import { useToastService } from "../../../../context/toast-service-context";
import { CreateExerciseDTO } from "../../../../models/exercises-dtos/create-exercise-dto";
import { AddExerciseLeftControllers } from "./controllers/add-exercise-left-controllers/add-exercise-left-controllers";
import { AddExerciseRightControllers } from "./controllers/add-exercise-right-controllers/add-exercise-right-controllers";
import { useNavigate } from "react-router-dom";

export const AddExercise = () => {
  const addExerciseFormMethods = useForm<AddExerciseInputs>();
  const navigate = useNavigate();

  const { showToast } = useToastService();

  const addNewExercise = useAddExercise();

  const onSubmit = addExerciseFormMethods.handleSubmit(async (inputs) => {
    try {
      const createExerciseDTO: CreateExerciseDTO = {
        name: inputs.name,
        burnedKCals: Number(inputs.burnedKCals),
        description: inputs.description,
        durationInMins: Number(inputs.durationInMins),
        thumbnailUploadedFileId: inputs.thumbnailUploadedFileId,
        mediaUploadedFileId: inputs.mediaUploadedFileId,
        restrictions: [],
      };

      await addNewExercise(createExerciseDTO).then((res) =>
        navigate(`/workouts/exercise/${res.id}`)
      );
      showToast("Success", "Success Adding Exercise");
    } catch (error) {
      showToast("Error", "Failed to Add Exercise :(");
    }
  });

  return (
    <FormProvider {...addExerciseFormMethods}>
      <form onSubmit={onSubmit}>
        <WorkoutsScreenHeader>
          <p>Exercise</p>
          <Button type="submit">Save</Button>
        </WorkoutsScreenHeader>
        <WorkoutsFormContainer>
          <WorkoutsFormControllersContainer>
            <AddExerciseLeftControllers />
            <AddExerciseRightControllers />
          </WorkoutsFormControllersContainer>
        </WorkoutsFormContainer>
      </form>
    </FormProvider>
  );
};
